import { InputFile } from '../../models/jobModels/ModelsFileTypes';
import { FileType } from '../../models/inputTypes/FileType';

interface DynamicFileObject {
  [key: string]: FileType | undefined;
}

export const dynamicInputFilePreparation = (fileObj: DynamicFileObject) => {
  const arrayOfFiles: InputFile[] = [];
  const keys = Object.keys(fileObj);
  keys.forEach((item) => {
    const project = fileObj[item]?.currentFile?.project;
    const name = fileObj[item]?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `${item}.csv`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  return arrayOfFiles;
};
