import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
import RadioLabel from '../../common/RadioLabel';
import { PmsDissipation } from '../../../models/inputTypes/PmsFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { pmsDissipationInitialState } from '../../../utils/initialStates/pmsInputStates';

const styles = {
  spaceBetweenBox: {
    width: '100%',
    maxWidth: '1300px',
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'flex-end',
  },
  spacingCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  flexEndBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
} as const;

type EnergyDissipationInputsProps = {
  projectId: string;
  inputState: PmsDissipation;
  setInputState: (value: PmsDissipation) => void;
};

const EnergyDissipationInputsGroup: FC<EnergyDissipationInputsProps> = ({ projectId, inputState, setInputState }) => {
  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'bottomFriction') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        waveBreakingType: 'constant',
        frictionFile: undefined,
        nikuradse: pmsDissipationInitialState.nikuradse,
      });
      return;
    }

    setInputState({
      ...inputState,
      [e.target.name]: e.target.checked,
      a: pmsDissipationInitialState.a,
      y1: pmsDissipationInitialState.y1,
      y2: pmsDissipationInitialState.y2,
    });
  };

  const onRadioClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const fieldName: 'constant' | 'varying' = e.currentTarget.id;
    setInputState({
      ...inputState,
      waveBreakingType: fieldName,
      frictionFile: undefined,
      nikuradse: pmsDissipationInitialState.nikuradse,
    });
  };

  const onRadioClickV = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const fieldName: 'constant' | 'varying' = e.currentTarget.id;
    setInputState({
      ...inputState,
      vegetationType: fieldName,
      stemsFile: undefined,
      stems: pmsDissipationInitialState.stems,
    });
  };

  return (
    <Box>
      <Box sx={{ ...styles.spaceBetweenBox }}>
        <Box>
          <Typography mb={2} variant={'subtitle1'}>
            Wave Breaking
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
            <Box>
              <LabelWrapper label={'Include wave breaking'}>
                <Box sx={{ ...styles.spacingCenterContainer }}>
                  <CustomSwitch checked={inputState.waveBreaking} name={'waveBreaking'} onChange={onSwitchChange} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.waveBreaking} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'a'}>
                  <CustomInput
                    disabled={!inputState.waveBreaking}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.a}
                    name={'a'}
                    onChange={onNumberChange}
                    maxWidth={'65px'}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'γ1'}>
                  <CustomInput
                    disabled={!inputState.waveBreaking}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.y1}
                    name={'y1'}
                    onChange={onNumberChange}
                    maxWidth={'65px'}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'γ2'}>
                  <CustomInput
                    disabled={!inputState.waveBreaking}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.y2}
                    name={'y2'}
                    onChange={onNumberChange}
                    maxWidth={'65px'}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
            <Box>
              <Typography mb={3} variant={'subtitle1'}>
                Bottom Friction
              </Typography>
              <Box>
                <LabelWrapper label={'Include bottom friction'}>
                  <Box sx={{ ...styles.spacingCenterContainer }}>
                    <CustomSwitch
                      checked={inputState.bottomFriction}
                      name={'bottomFriction'}
                      onChange={onSwitchChange}
                    />
                  </Box>
                </LabelWrapper>
              </Box>
            </Box>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.bottomFriction} mt={4}>
          <Box sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="constant" label={'Constant'} onClick={onRadioClick}>
                  <CustomRadio checked={inputState.waveBreakingType === 'constant'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.waveBreakingType !== 'constant'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Nikuradse roughness'}>
                  <CustomInput
                    disabled={!inputState.bottomFriction || inputState.waveBreakingType !== 'constant'}
                    min={0.00000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    required
                    value={inputState.nikuradse}
                    name={'nikuradse'}
                    onChange={onInputChange}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
          <Box mt={2} sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="varying" label={'Varying'} onClick={onRadioClick}>
                  <CustomRadio checked={inputState.waveBreakingType === 'varying'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.waveBreakingType !== 'varying'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Insert friction file'}>
                  <ButtonFileDialog
                    typePage={'PLOT'}
                    dataFilesOnly
                    required
                    projectId={projectId}
                    disabled={!projectId || inputState.waveBreakingType !== 'varying' || !inputState.bottomFriction}
                    file={inputState.frictionFile}
                    setFile={(file) => onFileChange(file, 'frictionFile')}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
        </BoxComponent>
      </Box>

      <Box sx={{ ...styles.spaceBetweenBox }}>
        <Box>
          <Typography mb={2} variant={'subtitle1'}>
            Attenuation due to vegetation
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
            <Box>
              <LabelWrapper label={'Include vegetation'}>
                <Box sx={{ ...styles.spacingCenterContainer }}>
                  <CustomSwitch checked={inputState.vegetation} name={'vegetation'} onChange={onSwitchChange} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.vegetation} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Calibration coefficient'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.coef}
                    name={'coef'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'Stem width (m)'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.width}
                    name={'width'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'Stem height (m)'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.height}
                    name={'height'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.vegetation} mt={4}>
          <Box sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="constant" label={'Constant'} onClick={onRadioClickV}>
                  <CustomRadio checked={inputState.vegetationType === 'constant'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.vegetationType !== 'constant'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Stems / m\u00B2'}>
                  <CustomInput
                    disabled={!inputState.vegetation || inputState.vegetationType !== 'constant'}
                    min={0.00000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    required
                    value={inputState.stems}
                    name={'stems'}
                    onChange={onInputChange}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
          <Box mt={2} sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="varying" label={'Varying'} onClick={onRadioClickV}>
                  <CustomRadio checked={inputState.vegetationType === 'varying'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.vegetationType !== 'varying'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Insert Stems / m\u00B2 file'}>
                  <ButtonFileDialog
                    typePage={'PLOT'}
                    dataFilesOnly
                    required
                    projectId={projectId}
                    disabled={!projectId || inputState.vegetationType !== 'varying' || !inputState.vegetation}
                    file={inputState.stemsFile}
                    setFile={(file) => onFileChange(file, 'stemsFile')}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
        </BoxComponent>
      </Box>
    </Box>
  );
};

export default memo(EnergyDissipationInputsGroup);
