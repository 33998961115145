import {
  HydBoundaryConditions,
  HydInitialConditions,
  HydModelDefinition,
  HydModelFeatures,
  HydNumericalDomain,
  HydOutput,
  HydSourceLinks,
} from '../../models/inputTypes/HydFields';

import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { HydType } from '../../models/jobModels/hydType';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';
import { hydOutputAreaFilePreparation, hydOutputLineFilePreparation } from './filePreparation/hydFilePreparation';
import {
  hydModelFeaturesInitialState,
  hydPlotAreasInitialState,
  hydPlotLinesInitialState,
} from '../initialStates/hydInputState';

export const HydPreparation = (
  numericalState: HydNumericalDomain,
  modelDefinitionState: HydModelDefinition,
  initialState: HydInitialConditions,
  boundaryState: HydBoundaryConditions,
  sourceState: HydSourceLinks,
  modelState: HydModelFeatures,
  outputState: HydOutput,
  submissionState: PmsSubmission,
): HydType | unknown => {
  const inputFiles = dynamicInputFilePreparation({
    I_Depths: numericalState.bathymetryFile,
    I_U_Hot: initialState.uFile,
    I_V_Hot: initialState.vFile,
    I_Z_Hot: initialState.zFile,
    I_Eddy_Map: modelState.eddyFile,
    I_Friction_Map: modelState.frictionFile,
    I_Sxx: modelState.sxxFile,
    I_Sxy: modelState.sxyFile,
    I_Syy: modelState.syyFile,
    I_WindVelDir: modelState.windFrictionFile,
    I_Veg_Map: modelState.stemsFile,
    I_Cdrag_Map: modelState.dragFile,
  });

  const outputArea = hydOutputAreaFilePreparation(outputState.plotAreaValues, numericalState.project);
  const outputLine = hydOutputLineFilePreparation(outputState.plotLineValues, numericalState.project);

  const preparedValues: HydType = {
    simulation: 'hyd',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: [...outputArea, ...outputLine],
      im: +numericalState.cellX,
      jm: +numericalState.cellY,
      dx: +numericalState.cellSizeX,
      dy: +numericalState.cellSizeY,
      m: +numericalState.waterLevel, //missing water shift input:FIXED
      isba1: +numericalState.swX,
      jsba1: +numericalState.swY,
      isba2: +numericalState.neX,
      jsba2: +numericalState.neY,

      convergence: modelDefinitionState.calculationType === 'instationary' ? 1 : 0,
      convergence_accuracy: +modelDefinitionState.criterion, //modelDefinitionState.calculationType === 'instationary' ? hydModelDefinitionInitialState.criterion : modelDefinitionState.criterion,
      solution_scheme:
        modelDefinitionState.filteringType === 'euler' ? 1 : modelDefinitionState.filteringType === '3rdOrder' ? 2 : 3,
      filter: modelDefinitionState.filtering ? 1 : 0,
      tend: +modelDefinitionState.simulationTime,
      dt: +modelDefinitionState.timeSteps,

      icold: initialState.coldStart ? 0 : 1,
      u: +initialState.uVelocity, //initialState.coldStart ? hydInitialConditionsInitialState.uVelocity :initialState.uVelocity
      v: +initialState.vVelocity, //initialState.coldStart ? hydInitialConditionsInitialState.vVelocity :initialState.vVelocity
      z: +initialState.zVelocity, //initialState.coldStart ? hydInitialConditionsInitialState.zVelocity :initialState.zVelocity

      boundaries_length: +boundaryState.boundariesLength,
      boundaries: boundaryState.boundaries.length
        ? boundaryState.boundaries.map((item) => ({
            fp_x: +item.fp_x,
            fp_y: +item.fp_y,
            lp_x: +item.lp_x,
            lp_y: +item.lp_y,
            condition_type: +item.condition_type,
            u: +item.u,
            v: +item.v,
            z: +item.z,
            tidal_amp: +item.tidal_amp,
            tidal_per: +item.tidal_per,
          }))
        : [{ fp_x: 1, fp_y: 1, lp_x: 1, lp_y: 1, condition_type: 1, u: 0, v: 0, z: 0, tidal_amp: 0, tidal_per: 0 }],

      source_points_length: +sourceState.pointsLength,
      source_points: sourceState.source_points.length
        ? sourceState.source_points.map((item) => ({
            point_type: +item.point_type,
            parameter_type: +item.parameter_type,
            x: +item.x,
            y: +item.y,
            param1: +item.param1,
            param2: +item.param2,
          }))
        : [{ point_type: 0, parameter_type: 1, x: 1, y: 1, param1: 0, param2: 0 }],

      icoriol: modelState.coriolis ? 1 : 0,
      average_latitude: +modelState.avgLatitute, //!modelState.coriolis ? hydModelFeaturesInitialState.avgLatitute : modelState.avgLatitute

      iwetdry: modelState.wettingDrying ? 1 : 0,
      wetting_depths: +modelState.wettingDepth, //!modelState.wettingDrying ? hydModelFeaturesInitialState.wettingDepth : modelState.wettingDepth
      drying_depths: +modelState.dryingDepth, //!modelState.wettingDrying ? hydModelFeaturesInitialState.dryingDepth : modelState.dryingDepth

      eddy: modelState.eddyViscosity ? (modelState.eddyType === 'varying' ? 2 : 1) : 0,
      eddy_coefficient: +modelState.eddyCoefficient, //!modelState.eddyViscosity || modelState.eddyType === 'varying' ? hydModelFeaturesInitialState.eddyCoefficient:modelState.eddyCoefficient

      friction: modelState.bottomFriction ? (modelState.bottomFrictionType === 'varying' ? 2 : 1) : 0,
      friction_coefficient: +modelState.bottomFrictionCoefficient, //!modelState.bottomFriction || modelState.bottomFrictionType === 'varying' ? hydModelFeaturesInitialState.bottomFrictionCoefficient:modelState.bottomFrictionCoefficient

      vgt: modelState.vegetation ? 1 : 0,
      coef_veg: modelState.coef,
      bveg: +modelState.width,
      hveg: +modelState.height,
      vgtType: modelState.vegetation ? (modelState.vegetationType === 'varying' ? 2 : 1) : 0,
      inveginc: +modelState.stems,
      vgtdragType: modelState.vegDragType === 'varying' ? 2 : 1,
      Adrag: +modelState.a,
      Bdrag: +modelState.b,
      Cdrag: +modelState.c,

      iradiation_stresses: modelState.waveRadiation ? 1 : 0,

      iwind: modelState.windForcing ? (modelState.windForcingType === 'varying' ? 2 : 1) : 0,
      wind_velocity: +modelState.uWind, //!modelState.windForcing || modelState.windForcingType === 'varying' ? hydModelFeaturesInitialState.uWind:modelState.uWind
      wind_direction: +modelState.direction, //!modelState.windForcing || modelState.windForcingType === 'varying' ? hydModelFeaturesInitialState.direction:modelState.direction
      wind_friction_flag: +modelState?.wind_friction_flag ?? +hydModelFeaturesInitialState.wind_friction_flag,
      constant_wind_friction_factor:
        modelState.constant_wind_friction_factor ?? hydModelFeaturesInitialState.constant_wind_friction_factor,
      plot_areas_length: +outputState.plotAreas,
      plot_lines_length: +outputState.plotLines,
      plot_areas: outputState.plotAreaValues.map((area) => ({
        sw_x: +area.sw_x ?? hydPlotAreasInitialState.sw_x,
        sw_y: +area.sw_y ?? hydPlotAreasInitialState.sw_y,
        ne_x: +area.ne_x ?? hydPlotAreasInitialState.ne_x,
        ne_y: +area.ne_y ?? hydPlotAreasInitialState.ne_y,
        u: +area.u ?? hydPlotAreasInitialState.u,
        v: +area.v ?? hydPlotAreasInitialState.v,
        z: +area.z ?? hydPlotAreasInitialState.z,
        speed: +area.speed,
        direction: +area.direction,
        depth: +area.depth,
        start_step: +area.start_step ?? hydPlotAreasInitialState.start_step,
        end_step: +area.end_step ?? hydPlotAreasInitialState.end_step,
        interval: +area.interval ?? hydPlotAreasInitialState.interval,
      })),

      plot_lines: outputState.plotLineValues.map((line) => ({
        fp_x: +line.fp_x ?? hydPlotLinesInitialState.fp_x,
        fp_y: +line.fp_y ?? hydPlotLinesInitialState.fp_y,
        lp_x: +line.lp_x ?? hydPlotLinesInitialState.lp_x,
        lp_y: +line.lp_y ?? hydPlotLinesInitialState.lp_y,
        u: +line.u ?? hydPlotLinesInitialState.u,
        v: +line.v ?? hydPlotLinesInitialState.v,
        z: +line.z ?? hydPlotLinesInitialState.z,
        speed: +line.speed ?? hydPlotLinesInitialState.speed,
        direction: +line.direction ?? hydPlotLinesInitialState.direction,
        depth: +line.depth ?? hydPlotLinesInitialState.depth,
        start_step: +line.start_step ?? hydPlotLinesInitialState.start_step,
        end_step: +line.end_step ?? hydPlotLinesInitialState.end_step,
        interval: +line.interval ?? hydPlotLinesInitialState.interval,
      })),
    },
  };
  return preparedValues;
};
