import React, { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import ProfileContainer from '../navigation/ProfileContainer';
import { ReactComponent as LogoIcon } from '../../assets/images/scientiaMaris.svg';
import StorageComponent from '../common/StorageComponent';
import { useHistory } from 'react-router';
import { useLogoutMutation } from '../../redux/RTK/mutations/loginMutations';
import { useUserQuery } from '../../redux/RTK/queries/userQuery';
import { useStorageQuery } from '../../redux/RTK/queries/storageQuery';
import CpuComponent from '../common/CpuComponent';
import NavAccordion from './NavAccordion';
import { adminLinks, numericalModelsLinks, numericalToolsLinks, plotAnimationLinks } from '../../dummy/navigationLinks';

type SidebarType = {
  width: any;
};

const root = {
  overflow: 'auto',
  bgcolor: 'info.contrastText',
};

const Sidebar: FC<SidebarType> = ({ width = 260 }) => {
  const [logout] = useLogoutMutation();
  const { data } = useUserQuery({});
  const { data: storage } = useStorageQuery({}, { pollingInterval: 60000 });

  const history = useHistory();

  const onSettingsClick = () => {
    history.push('/profile');
  };

  const onLogoutClick = () => {
    logout({});
  };

  const cores = useMemo(() => {
    if (storage?.profile) {
      const coresUsed = storage.profile?.cores_used ?? 0;
      const coresQuota = storage.profile?.cores_quota ?? 0;
      return { progress: (coresUsed / coresQuota) * 100, used: coresUsed, total: coresQuota };
    }
    return { progress: 0, used: 0, total: 0 };
  }, [storage]);

  const preparedStorage = useMemo(() => {
    if (storage?.profile) {
      const storage_quota = storage.profile?.storage_quota ?? 0;
      const storage_used = storage.profile?.storage_used ?? 0;
      const progress = (storage_used / storage_quota) * 100;
      const total = (storage_quota / (1024 * 1024 * 1024)).toFixed(1);
      const used = (storage_used / (1024 * 1024 * 1024)).toFixed(1);
      return { progress, total, used };
    }
    return { progress: 0, total: 0, used: 0 };
  }, [storage]);

  return (
    <Grid container alignContent={'space-between'} sx={{ minWidth: width, maxWidth: width, ...root }}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <ProfileContainer
            firstName={`${data?.first_name}`}
            lastName={`${data?.last_name}`}
            tag={`@${data?.username}`}
            onLogoutClick={onLogoutClick}
            onSettingsClick={onSettingsClick}
          />
        </Grid>

        <NavAccordion title={'ADMINISTRATION'} barLinks={adminLinks} />
        <NavAccordion title={'NUMERICAL TOOLS'} barLinks={numericalToolsLinks} />
        <NavAccordion title={'NUMERICAL MODELS'} barLinks={numericalModelsLinks} />
        <NavAccordion title={'PLOT'} barLinks={plotAnimationLinks} />

        {location.pathname === '/profile' ? (
          <></>
        ) : (
          <>
            <Grid item xs={12} sx={{ padding: '0px 16px' }}>
              <StorageComponent
                progress={preparedStorage.progress}
                usedCount={+preparedStorage.used}
                totalCount={+preparedStorage.total}
              />
            </Grid>
            <Grid item xs={12} sx={{ padding: '0px 16px' }}>
              <CpuComponent progress={cores.progress} usedCount={cores.used} totalCount={cores.total} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container mt={2} mb={4} justifyContent={'center'} xs={12}>
        <LogoIcon />
      </Grid>
    </Grid>
  );
};

export default Sidebar;
