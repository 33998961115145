import { get, post, put } from '../index';

export const postHeatmap = (
  projectId: string,
  fileName: string,
  max: number | undefined = undefined,
  min: number | undefined = undefined,
  levels: number | undefined = undefined,
  color: string | undefined = undefined,
  mask: any | undefined = undefined,
  replace_operation: any | undefined = undefined,
  timeframe: number | undefined = undefined,
  data: number[][] | undefined = undefined,
  x: number | undefined = undefined,
  y: number | undefined = undefined,
) => {
  return post(`heatmap/plot/${projectId}/${fileName}`, {
    max: max,
    min: min,
    levels: levels,
    color: color,
    mask: mask,
    replace_operation: replace_operation,
    timeframe: timeframe,
    data: data,
    x: x,
    y: y,
  });
};

export const getFrame = (
  projectId: string,
  fileName: string,
  timeframe: number | undefined = undefined,
  x: number | undefined = undefined,
  y: number | undefined = undefined,
) => {
  return get(`heatmap/frame/${projectId}/${fileName}`, {
    params: {
      timeframe: timeframe,
      x: x,
      y: y,
    },
  });
};

export const putFile = (
  projectId: string,
  fileName: string,
  destinationFile: string | undefined,
  timeframe: number | undefined = undefined,
  data: number[][] | undefined = undefined,
  x: number | undefined = undefined,
  y: number | undefined = undefined,
  replace_operation: any | undefined = undefined,
) => {
  return put(`heatmap/save/${projectId}/${fileName}`, {
    timeframe: timeframe,
    data: data,
    x: x,
    y: y,
    destinationFile: destinationFile,
    replace_operation,
  });
};
