import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Collapse, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import SdtModelDefinitionInputsGroup from '../../components/groups/sdt/SdtModelDefinitionInputsGroup';
import SdtModelFeaturesInputsGroup from '../../components/groups/sdt/SdtModelFeaturesInputsGroup';
import SdtInputForcingInputsGroup from '../../components/groups/sdt/SdtInputForcingInputsGroup';
import CustomTabs from '../../components/custom/CustomTabs';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';
import { plotTabPreparation } from '../../utils/functions/PlotTabPreparation';
import {
  sdtInputForcingInitialState,
  sdtModelDefinitionInitialState,
  sdtModelFeaturesInitialState,
  sdtNumericalDomainInitialState,
  sdtOutputInitialState,
  sdtPlotAreaInitialState,
  sdtPlotLineInitialState,
} from '../../utils/initialStates/sdtInputState';
import {
  SdtInputForcing,
  SdtModelDefinition,
  SdtModelFeatures,
  SdtNumericalDomain,
  SdtOutput,
  SdtPlotArea,
  SdtPlotLine,
} from '../../models/inputTypes/SdtFields';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { sdtPreparation } from '../../utils/modelPreparation/sdtPreparation';
import SdtOutputInputsGroup from '../../components/groups/sdt/SdtOutputInputsGroup';
import SdtPlotAreasInputsGroup from '../../components/groups/sdt/SdtPlotAreasInputsGroup';
import SdtPlotLinesInputsGroup from '../../components/groups/sdt/SdtPlotLinesInputsGroup';
import { SdtSimulationPreparationResponse } from '../../utils/simulationFileToState/sdtSimulationPreparation';
import { FileType } from '../../models/inputTypes/FileType';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAppDispatch } from '../../redux/store';
import SdtNumericalDomainInputsGroup from '../../components/groups/sdt/SdtNumericalDomainInputsGroup';
import { hmsPlotAreasInitialState } from '../../utils/initialStates/hmsInputState';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';

const MarisSdtPage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const [selectedPlots, setSelectedPlots] = useState<{ line: number; area: number }>({ line: 0, area: 0 });
  const [uploadFile] = useAddProjectFileMutation();
  const [createJob] = useCreateJobsMutation();
  const dispatch = useAppDispatch();

  const [numericalState, setNumericalState] = useState<SdtNumericalDomain>(sdtNumericalDomainInitialState);
  const [modelDefinitionState, setModelDefinitionState] = useState<SdtModelDefinition>(sdtModelDefinitionInitialState);
  const [modelFeaturesState, setModelFeaturesState] = useState<SdtModelFeatures>(sdtModelFeaturesInitialState);
  const [inputForcingState, setInputForcingState] = useState<SdtInputForcing>(sdtInputForcingInitialState);
  const [outputState, setOutputState] = useState<SdtOutput>(sdtOutputInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const [areaInitial, setAreaInitial] = useState<SdtPlotArea>(sdtPlotAreaInitialState);
  const [lineInitial, setLineInitial] = useState<SdtPlotLine>(sdtPlotLineInitialState);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.SDT);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const numChanged = numericalState != sdtNumericalDomainInitialState;
      const defChanged = modelDefinitionState != sdtModelDefinitionInitialState;
      const condChanged = modelFeaturesState != sdtModelFeaturesInitialState;
      const boundChanged = inputForcingState != sdtInputForcingInitialState;
      const outChanged = outputState != sdtOutputInitialState;
      const subChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(boundChanged || defChanged || numChanged || condChanged || subChanged || outChanged);
    }
  }, [numericalState, modelDefinitionState, modelFeaturesState, inputForcingState, outputState, submissionState]);

  useEffect(() => {
    const floorValue = Math.floor(modelDefinitionState.simulationTime / modelDefinitionState.timeStep);
    setAreaInitial({
      ...areaInitial,
      end_step: floorValue,
      interval: floorValue,
    });
    setLineInitial({
      ...lineInitial,
      end_step: floorValue,
      interval: floorValue,
    });
  }, [modelDefinitionState.simulationTime, modelDefinitionState.timeStep]);

  useEffect(() => {
    setAreaInitial({
      ...areaInitial,
      ne_x: numericalState.cellX ?? hmsPlotAreasInitialState.ne_x,
      ne_y: numericalState.cellY ?? hmsPlotAreasInitialState.ne_y,
    });
  }, [numericalState.cellX, numericalState.cellY]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const plotAreaTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotAreas, 'area');
  }, [outputState.plotAreas]);

  const plotLineTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotLines, 'line');
  }, [outputState.plotLines]);

  const plotHasTimeParams = useMemo(
    () => modelDefinitionState.calculationType !== 'varying',
    [modelDefinitionState.calculationType],
  );

  const calculationDisabled = useMemo(
    () => modelDefinitionState.calculationType == 'stationary',
    [modelDefinitionState.calculationType],
  );

  const onLineChange = (event: any, newValue: number) => {
    setSelectedPlots({ ...selectedPlots, [event.currentTarget.id]: newValue });
  };

  const onSimulationSave = () => {
    const preparedValue = sdtPreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      inputForcingState,
      outputState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (value: SdtSimulationPreparationResponse, file?: FileType) => {
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setModelDefinitionState(value.modelDefinition);
    setModelFeaturesState(value.modelFeatures);
    setInputForcingState(value.inputForcing);
    setOutputState(value.output);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  const onNoOutputAccept = () => {
    const preparedValue = sdtPreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      inputForcingState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
    setSaveNoOutput(false);
  };

  const onSubmitClick = (e: FormEvent<any>) => {
    e.preventDefault();
    if (!outputState.plotAreas && !outputState.plotLines) {
      setSaveNoOutput(true);
      return;
    }
    const preparedValue = sdtPreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      inputForcingState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  const onModelDefinitionChange = (modelDef: SdtModelDefinition) => {
    if (modelDef.calculationType !== modelDefinitionState.calculationType && modelDef.calculationType == 'stationary') {
      setModelFeaturesState({ ...modelFeaturesState, startTimeStep: sdtModelFeaturesInitialState.startTimeStep });
      setInputForcingState({
        ...inputForcingState,
        fluxSources: sdtInputForcingInitialState.fluxSources,
        startExternalSources: sdtInputForcingInitialState.startExternalSources,
        endExternalSources: sdtInputForcingInitialState.endExternalSources,
        I_Sources: undefined,
      });
    }
    setModelDefinitionState(modelDef);
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'Maris SDT'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/maris-sdt' ? true : 'Changes that you made may not be saved.'
        }
      />
      <form onSubmit={onSubmitClick}>
        <Typography variant={'h4'}>Numerical Domain</Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SdtNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model definition
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SdtModelDefinitionInputsGroup inputState={modelDefinitionState} setInputState={onModelDefinitionChange} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SdtModelFeaturesInputsGroup
            projectId={numericalState.project}
            inputState={modelFeaturesState}
            setInputState={setModelFeaturesState}
            calculationDisabled={calculationDisabled}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Input Forcing
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SdtInputForcingInputsGroup
            projectId={numericalState.project}
            sediment={modelFeaturesState.sediment}
            inputState={inputForcingState}
            setInputState={setInputForcingState}
            calculationDisabled={calculationDisabled}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Output
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SdtOutputInputsGroup
            inputState={outputState}
            setInputState={setOutputState}
            areaInitial={areaInitial}
            lineInitial={lineInitial}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Areas
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotAreas !== 0} collapsedSize={1}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.area}
              name={'area'}
              onChange={onLineChange}
            >
              {plotAreaTabs}
            </CustomTabs>
            {outputState.plotAreaValues[selectedPlots.area] ? (
              <SdtPlotAreasInputsGroup
                removeTimeParams={plotHasTimeParams}
                inputState={outputState.plotAreaValues[selectedPlots.area]}
                setInputState={(newArea) => {
                  const newPlotArray = outputState.plotAreaValues.slice();
                  newPlotArray[selectedPlots.area] = { ...newPlotArray[selectedPlots.area], ...newArea };
                  setOutputState({ ...outputState, plotAreaValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Lines
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotLines !== 0} collapsedSize={5}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.line}
              name={'line'}
              onChange={onLineChange}
            >
              {plotLineTabs}
            </CustomTabs>
            {outputState.plotLineValues[selectedPlots.line] ? (
              <SdtPlotLinesInputsGroup
                removeTimeParams={plotHasTimeParams}
                inputState={outputState.plotLineValues[selectedPlots.line]}
                setInputState={(newLine) => {
                  const newPlotArray = outputState.plotLineValues.slice();
                  newPlotArray[selectedPlots.line] = newLine;
                  setOutputState({ ...outputState, plotLineValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisSdtPage;
