import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { PmsType } from '../../models/jobModels/pmsType';
import {
  PlotArea,
  PlotLine,
  PmsBoundaryConditions,
  PmsDissipation,
  PmsModelFeatures,
  PmsNumericDomain,
  PmsOutput,
  PmsSubmission,
} from '../../models/inputTypes/PmsFields';
import { OutputFile } from '../../models/jobModels/ModelsFileTypes';
import {
  plotAreasInitialState,
  plotLinesInitialState,
  pmsPlotAreasAliases,
  pmsPlotLinesAliases,
  pmsDissipationInitialState,
} from '../initialStates/pmsInputStates';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import { findOutputFileName } from '../functions/findOutputFileName';

export type PmsSimulationPreparationResponse = {
  numerical: PmsNumericDomain;
  boundary: PmsBoundaryConditions;
  modelFeature: PmsModelFeatures;
  energyDissipation: PmsDissipation;
  output: PmsOutput;
  submission: PmsSubmission;
};

export const pmsSimulationPreparation = (simulationFile: PmsType): PmsSimulationPreparationResponse | undefined => {
  const preparedDepthFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Depths.csv');
  const frictionFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Friction_Map.csv');
  const vegetationFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Veg_Map.csv');

  const numerical: PmsNumericDomain = {
    project: simulationFile.project.toString(),
    cellX: +simulationFile.parameters.im,
    cellY: +simulationFile.parameters.jm,
    cellSizeX: +simulationFile.parameters.dx,
    cellSizeY: +simulationFile.parameters.dy,
    bathymetricData: preparedDepthFile,
    waterLevel: +simulationFile.parameters.m,
  };

  const boundary: PmsBoundaryConditions = {
    boundaryType: simulationFile.parameters.wgbl.toString(),
    coord1: simulationFile.parameters.lbt1.toString(),
    coord2: simulationFile.parameters.lbt2.toString(),
    seaState: simulationFile.parameters.ss.toString(),
    hm: +simulationFile.parameters.Ho,
    ts: +simulationFile.parameters.per,
    direction: +simulationFile.parameters.thita,
    numberOfPeriods: +simulationFile.parameters.period,
    spectrum: simulationFile.parameters.spectrum.toString(),
    depthWavemaker: +simulationFile.parameters.depth_wavemaker,
    minPeriod: +simulationFile.parameters.min_period,
    maxPeriod: +simulationFile.parameters.max_period,
    peakFactorY: +simulationFile.parameters.gamma,
  };

  const modelFeature: PmsModelFeatures = {
    nonlinearDispersion: simulationFile.parameters.lnr_f == 2,
    iterations: +simulationFile.parameters.nlnr_iter,
    relation: simulationFile.parameters.nlnr_eq.toString(),
    approximitation: simulationFile.parameters.coef.toString(),
    degrees: +simulationFile.parameters.coef_mm,
  };

  const energyDissipation: PmsDissipation = {
    waveBreaking: simulationFile.parameters.brk_f == 1,
    a: +simulationFile.parameters.brk_coef_a,
    y1: +simulationFile.parameters.brk_coef_g1,
    y2: +simulationFile.parameters.brk_coef_g2,
    bottomFriction: simulationFile.parameters.frctn !== 0,
    waveBreakingType: simulationFile.parameters.frctn == 2 ? 'varying' : 'constant',
    nikuradse: simulationFile.parameters.frctn_coef.toString(),
    frictionFile: frictionFile,
    vegetation: simulationFile.parameters.vgt ? simulationFile.parameters.vgt !== 0 : false,
    coef: simulationFile.parameters.coef_veg ? simulationFile.parameters.coef_veg : pmsDissipationInitialState.coef,
    width: simulationFile.parameters.bveg ? simulationFile.parameters.bveg : pmsDissipationInitialState.width,
    height: simulationFile.parameters.hveg ? simulationFile.parameters.hveg : pmsDissipationInitialState.height,
    vegetationType: simulationFile.parameters.vgtType == 2 ? 'varying' : 'constant',
    stems: simulationFile.parameters.inveginc ? simulationFile.parameters.inveginc : pmsDissipationInitialState.stems,
    stemsFile: vegetationFile,
  };

  const output: PmsOutput = {
    plotAreas: +simulationFile.parameters.plot_areas_length,
    plotLines: +simulationFile.parameters.plot_lines_length,
    plotAreaValues: preparePmsPlotAreas(simulationFile.parameters.plot_areas, simulationFile.parameters.output_files),
    plotLineValues: preparePmsPlotLines(simulationFile.parameters.plot_lines, simulationFile.parameters.output_files),
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return { numerical, boundary, modelFeature, energyDissipation, output, submission };
};

const preparePmsPlotAreas = (areas: Partial<PlotArea>[], outputFiles: OutputFile[]) => {
  const preparedAreas: PlotArea[] = [];
  areas.map((area, i) => {
    preparedAreas.push({
      sw_x: area.sw_x ?? plotAreasInitialState.sw_x,
      sw_y: area.sw_y ?? plotAreasInitialState.sw_y,
      ne_x: area.ne_x ?? plotAreasInitialState.ne_x,
      ne_y: area.ne_y ?? plotAreasInitialState.ne_y,
      h: area.h ?? plotAreasInitialState.h,
      c: area.c ?? plotAreasInitialState.c,
      z: area.z ?? plotAreasInitialState.z,
      dir: area.dir ?? plotAreasInitialState.dir,
      rad: area.rad ?? plotAreasInitialState.rad,
      init_depths: area.init_depths ?? plotAreasInitialState.init_depths,
      name_h: findOutputFileName(outputFiles, pmsPlotAreasAliases.name_h, i + 1, plotAreasInitialState.name_h, !area.h),
      name_c: findOutputFileName(outputFiles, pmsPlotAreasAliases.name_c, i + 1, plotAreasInitialState.name_c, !area.c),
      name_z: findOutputFileName(outputFiles, pmsPlotAreasAliases.name_z, i + 1, plotAreasInitialState.name_z, !area.z),
      name_dir: findOutputFileName(
        outputFiles,
        pmsPlotAreasAliases.name_dir,
        i + 1,
        plotAreasInitialState.name_dir,
        !area.dir,
      ),
      name_rad_xx: findOutputFileName(
        outputFiles,
        pmsPlotAreasAliases.name_rad_xx,
        i + 1,
        plotAreasInitialState.name_rad_xx,
        !area.rad,
      ),
      name_rad_xy: findOutputFileName(
        outputFiles,
        pmsPlotAreasAliases.name_rad_xy,
        i + 1,
        plotAreasInitialState.name_rad_xy,

        !area.rad,
      ),
      name_rad_yy: findOutputFileName(
        outputFiles,
        pmsPlotAreasAliases.name_rad_yy,
        i + 1,
        plotAreasInitialState.name_rad_yy,

        !area.rad,
      ),
      name_init_depths: findOutputFileName(
        outputFiles,
        pmsPlotAreasAliases.name_init_depths,
        i + 1,
        plotAreasInitialState.name_init_depths,
        !area.init_depths,
      ),
    });
  });
  return preparedAreas;
};

const preparePmsPlotLines = (lines: Partial<PlotLine>[], outputFiles: OutputFile[]) => {
  const preparedLines: PlotLine[] = [];
  lines.map((line, i) => {
    preparedLines.push({
      fp_x: line.fp_x ?? plotLinesInitialState.fp_x,
      fp_y: line.fp_y ?? plotLinesInitialState.fp_y,
      lp_x: line.lp_x ?? plotLinesInitialState.lp_x,
      lp_y: line.lp_y ?? plotLinesInitialState.lp_y,
      h: line.h ?? plotLinesInitialState.h,
      c: line.c ?? plotLinesInitialState.c,
      z: line.z ?? plotLinesInitialState.z,
      dir: line.dir ?? plotLinesInitialState.dir,
      name_h: findOutputFileName(outputFiles, pmsPlotLinesAliases.name_h, i + 1, plotLinesInitialState.name_h, !line.h),
      name_z: findOutputFileName(outputFiles, pmsPlotLinesAliases.name_z, i + 1, plotLinesInitialState.name_z, !line.z),
      name_c: findOutputFileName(outputFiles, pmsPlotLinesAliases.name_c, i + 1, plotLinesInitialState.name_c, !line.c),
      name_dir: findOutputFileName(
        outputFiles,
        pmsPlotLinesAliases.name_dir,
        i + 1,
        plotLinesInitialState.name_dir,
        !line.dir,
      ),
    });
  });
  return preparedLines;
};
