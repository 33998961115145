import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { PmsNumericDomain, PmsSubmission } from '../../models/inputTypes/PmsFields';
import { OutputFile } from '../../models/jobModels/ModelsFileTypes';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import { findOutputFileName } from '../functions/findOutputFileName';
import { HmsType } from '../../models/jobModels/hmsType';
import {
  HmsBoundaryConditions,
  HmsEnergyDissipation,
  HmsModelFeatures,
  HmsOutput,
  HmsPlotArea,
  HmsPlotLine,
  HmsSimulationTime,
} from '../../models/inputTypes/HmsFields';
import {
  hmsPlotAreasAliases,
  hmsPlotAreasInitialState,
  hmsPlotLinesAliases,
  hmsPlotLinesInitialState,
  hmsEnergyDissipationInitialState,
} from '../initialStates/hmsInputState';

export type HmsSimulationPreparationResponse = {
  numerical: PmsNumericDomain;
  boundary: HmsBoundaryConditions;
  simulation: HmsSimulationTime;
  modelFeature: HmsModelFeatures;
  energyDissipation: HmsEnergyDissipation;
  output: HmsOutput;
  submission: PmsSubmission;
};

export const hmsSimulationPreparation = (simulationFile: HmsType): HmsSimulationPreparationResponse | undefined => {
  const preparedDepthFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Depths.csv');
  const frictionFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Friction_Map.csv');
  const eddyFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Eddy_Map.csv');
  const spongeFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Sponge_Map.csv');
  const celFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Cel_Map.csv');
  const vegetationFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Veg_Map.csv');

  const numerical: PmsNumericDomain = {
    project: simulationFile.project.toString(),
    cellX: +simulationFile.parameters.im,
    cellY: +simulationFile.parameters.jm,
    cellSizeX: +simulationFile.parameters.dx,
    cellSizeY: +simulationFile.parameters.dy,
    bathymetricData: preparedDepthFile,
    waterLevel: +simulationFile.parameters.m,
  };

  const boundary: HmsBoundaryConditions = {
    seaState: simulationFile.parameters.sea_state.toString(),
    hm: +simulationFile.parameters.h,
    ts: +simulationFile.parameters.t,
    direction: +simulationFile.parameters.mwd,
    generatorNumber: +simulationFile.parameters.number_wave_generators,
    wave_generators_line_coordinates: simulationFile.parameters.wave_generators_line_coordinates.slice(
      0,
      +simulationFile.parameters.number_wave_generators,
    ),

    numberOfPeriods: +simulationFile.parameters.period,
    spectrum: simulationFile.parameters.spectrum.toString(),
    depthWavemaker: +simulationFile.parameters.depth_wavemaker,
    minPeriod: +simulationFile.parameters.min_period,
    maxPeriod: +simulationFile.parameters.max_period,
    peakFactorY: +simulationFile.parameters.gamma,
    mwdDeviation: +simulationFile.parameters.dir_deviation,
    directionalSpreading: +simulationFile.parameters.dir_spreading,
    numberOfDirections: +simulationFile.parameters.dirs,
  };

  const simulation: HmsSimulationTime = {
    simulationTime: +simulationFile.parameters.simtime,
    timeStep: +simulationFile.parameters.sleeptime,
    convergence: simulationFile.parameters.steadystate == 1,
    criterion: +simulationFile.parameters.convergence,
  };
  const modelFeature: HmsModelFeatures = {
    dispersion: simulationFile.parameters.nonlinearity.toString(),
    startingTime: +simulationFile.parameters.nonlinear_starting_time.toString(),
    relation: simulationFile.parameters.nonlinear_equation.toString(),
    celerityFile: celFile,
  };

  const energyDissipation: HmsEnergyDissipation = {
    waveBreaking: simulationFile.parameters.breaking == 1,
    a: +simulationFile.parameters.brk_coef_a,
    y1: +simulationFile.parameters.brk_coef_g1,
    y2: +simulationFile.parameters.brk_coef_g2,
    bottomFriction: simulationFile.parameters.friction !== 0,
    bottomFrictionType: simulationFile.parameters.friction == 2 ? 'varying' : 'constant',
    coefficient: simulationFile.parameters.friction_coefficient.toString(),
    frictionFile: frictionFile,

    vegetation: simulationFile.parameters.vgt ? simulationFile.parameters.vgt !== 0 : false,
    coef: simulationFile.parameters.coef_veg
      ? simulationFile.parameters.coef_veg
      : hmsEnergyDissipationInitialState.coef,
    width: simulationFile.parameters.bveg ? simulationFile.parameters.bveg : hmsEnergyDissipationInitialState.width,
    height: simulationFile.parameters.hveg ? simulationFile.parameters.hveg : hmsEnergyDissipationInitialState.height,
    vegetationType: simulationFile.parameters.vgtType == 2 ? 'varying' : 'constant',
    stems: simulationFile.parameters.inveginc
      ? simulationFile.parameters.inveginc
      : hmsEnergyDissipationInitialState.stems,
    stemsFile: vegetationFile,

    spongeLayer: simulationFile.parameters.sponge_map !== 0,
    spongeLayerType: simulationFile.parameters.sponge_map == 2 ? 'varying' : 'perimetric',
    west: +simulationFile.parameters.sp_layer_west,
    north: +simulationFile.parameters.sp_layer_north,
    east: +simulationFile.parameters.sp_layer_east,
    south: +simulationFile.parameters.sp_layer_south,
    spongeFile: spongeFile,

    eddyLayer: simulationFile.parameters.eddy !== 0,
    eddyType: simulationFile.parameters.eddy == 2 ? 'varying' : 'constant',
    eddyCoefficient: simulationFile.parameters.eddy_coefficient.toString(),
    eddyFile: eddyFile,
  };

  const output: HmsOutput = {
    plotAreas: +simulationFile.parameters.plot_areas_length,
    plotLines: +simulationFile.parameters.plot_lines_length,
    plotAreaValues: prepareHmsPlotAreas(simulationFile.parameters.plot_areas, simulationFile.parameters.output_files),
    plotLineValues: prepareHmsPlotLines(simulationFile.parameters.plot_lines, simulationFile.parameters.output_files),
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return { numerical, boundary, simulation, modelFeature, energyDissipation, output, submission };
};

const prepareHmsPlotAreas = (areas: Partial<HmsPlotArea>[], outputFiles: OutputFile[]) => {
  const preparedAreas: HmsPlotArea[] = [];
  areas.map((area, i) => {
    preparedAreas.push({
      ne_x: area.ne_x ?? 0,
      ne_y: area.ne_y ?? 0,
      sw_x: area.sw_x ?? 0,
      sw_y: area.sw_y ?? 0,
      h: area.h ?? 0,
      z: area.z ?? 0,
      rad: area.rad ?? 0,
      init_depths: area.init_depths ?? 0,
      name_h: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_h,
        i + 1,
        hmsPlotAreasInitialState.name_h,
        !area.h,
      ),
      name_z: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_z,
        i + 1,
        hmsPlotAreasInitialState.name_z,
        !area.z,
      ),
      name_rad_xx: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_rad_xx,
        i + 1,
        hmsPlotAreasInitialState.name_rad_xx,
        !area.rad,
      ),
      name_rad_xy: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_rad_xy,
        i + 1,
        hmsPlotAreasInitialState.name_rad_xy,
        !area.rad,
      ),
      name_rad_yy: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_rad_yy,
        i + 1,
        hmsPlotAreasInitialState.name_rad_yy,
        !area.rad,
      ),
      name_init_depths: findOutputFileName(
        outputFiles,
        hmsPlotAreasAliases.name_init_depths,

        i + 1,
        hmsPlotAreasInitialState.name_init_depths,
        !area.init_depths,
      ),
      start_step: area?.start_step ?? hmsPlotAreasInitialState.start_step,
      end_step: area?.end_step ?? hmsPlotAreasInitialState.end_step,
      interval: area?.interval ?? hmsPlotAreasInitialState.interval,
    });
  });
  return preparedAreas;
};

const prepareHmsPlotLines = (lines: Partial<HmsPlotLine>[], outputFiles: OutputFile[]) => {
  const preparedLines: HmsPlotLine[] = [];
  lines.map((line, i) => {
    preparedLines.push({
      fp_x: line.fp_x ?? 0,
      fp_y: line.fp_y ?? 0,
      lp_x: line.lp_x ?? 0,
      lp_y: line.lp_y ?? 0,
      h: line.h ?? 0,
      z: line.z ?? 0,
      name_h: findOutputFileName(
        outputFiles,
        hmsPlotLinesAliases.name_h,
        i + 1,
        hmsPlotLinesInitialState.name_h,
        !line.h,
      ),
      name_z: findOutputFileName(
        outputFiles,
        hmsPlotLinesAliases.name_z,
        i + 1,
        hmsPlotLinesInitialState.name_z,
        !line.z,
      ),
      start_step: line?.start_step ?? hmsPlotLinesInitialState.start_step,
      end_step: line?.end_step ?? hmsPlotLinesInitialState.end_step,
      interval: line?.interval ?? hmsPlotLinesInitialState.interval,
    });
  });
  return preparedLines;
};
