import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getFrame, postHeatmap, putFile } from '../../axios/endpoints/editFile';
import { openErrorToast } from './appSlice';

interface EditFileState {
  heatmapData: any;
  frameData: any;
  savedFile: any;
  loading: {
    heatmap: boolean;
    frame: boolean;
    save: boolean;
  };
  error: {
    heatmap: string | undefined;
    frame: string | undefined;
    save: string | undefined;
  };
}

const initialState: EditFileState = {
  heatmapData: undefined,
  frameData: undefined,
  savedFile: undefined,
  loading: {
    heatmap: false,
    frame: false,
    save: false,
  },
  error: {
    heatmap: undefined,
    frame: undefined,
    save: undefined,
  },
};

const editFileSlice = createSlice({
  name: 'editFile',
  initialState,
  reducers: {
    setHeatmapData: (state, action) => {
      state.heatmapData = action.payload;
    },
    setFrameData: (state, action) => {
      state.frameData = action.payload;
    },
    setSavedFile: (state, action) => {
      state.savedFile = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = { ...state.loading, [action.payload.key]: action.payload.value };
    },
    setError: (state, action) => {
      state.error = { ...state.error, [action.payload.key]: action.payload.value };
    },
    reset: () => initialState,
  },
});

export const selectEditFileHeatmap = (state: RootState) => state.editFile.heatmapData;
export const selectEditFileFrame = (state: RootState) => state.editFile.frameData;
export const selectEditFileLoading = (state: RootState) => state.editFile.loading;
export const selectEditFileError = (state: RootState) => state.editFile.error;
export const selectEditFileSaved = (state: RootState) => state.editFile.savedFile;

export const performGetHeatmap =
  (
    projectId: string,
    fileName: string,
    max: number | undefined = undefined,
    min: number | undefined = undefined,
    levels: number | undefined = undefined,
    color: string | undefined = undefined,
    mask: any | undefined = undefined,
    replace_operation: any | undefined = undefined,
    timeframe: number | undefined = undefined,
    data: number[][] | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'heatmap', value: true }));

    try {
      const res: any = await postHeatmap(
        projectId,
        fileName,
        max,
        min,
        levels,
        color,
        mask,
        replace_operation,
        timeframe,
        data,
        x,
        y,
      );
      if (res?.data?.data) {
        dispatch(setHeatmapData(res.data.data));
      }
      dispatch(setErrorEditFile({ key: 'heatmap', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'heatmap', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'heatmap', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'heatmap', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetFrame =
  (
    projectId: string,
    fileName: string,
    timeframe: number | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'frame', value: true }));
    try {
      const res: any = await getFrame(projectId, fileName, timeframe, x, y);
      if (res?.data) {
        dispatch(setFrameData(res.data));
      }
      dispatch(setErrorEditFile({ key: 'frame', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'frame', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'frame', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'frame', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performPutFile =
  (
    projectId: string,
    fileName: string,
    destinationFile: string | undefined,
    timeframe: number | undefined = undefined,
    data: number[][] | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
    replace_operation: any | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'save', value: true }));
    try {
      const res: any = await putFile(projectId, fileName, destinationFile, timeframe, data, x, y, replace_operation);
      if (destinationFile) {
        dispatch(setSavedFile({ projectId: projectId, destinationFile: destinationFile }));
      } else {
        dispatch(setSavedFile({ projectId: projectId, fileName: fileName }));
      }
      dispatch(setErrorEditFile({ key: 'save', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'save', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'save', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'save', value: false }));
      dispatch(openErrorToast(e.message));
    }
  };

export const {
  setHeatmapData,
  setFrameData,
  setSavedFile,
  setLoading: setLoadingEditFile,
  setError: setErrorEditFile,
  reset: resetEditFile,
} = editFileSlice.actions;
export default editFileSlice.reducer;
