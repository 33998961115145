import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import { SrfNumericalDomain } from '../../../models/inputTypes/SrfFields';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { srfSimulationPreparationResponse } from '../../../utils/simulationFileToState/srfSimulationPreparation';
import { SdtSimulationPreparationResponse } from '../../../utils/simulationFileToState/sdtSimulationPreparation';
import { useHistory, useParams } from 'react-router';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      my: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type SimulationResponse = srfSimulationPreparationResponse | SdtSimulationPreparationResponse;

type SrfNumericalDomainInputsProps = {
  inputState: SrfNumericalDomain;
  setInputState: (value: SrfNumericalDomain) => void;
  simulationChangeFile?: (value: SimulationResponse, file?: FileType) => void;
};

const SrfNumericalDomainInputsGroup: FC<SrfNumericalDomainInputsProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const history = useHistory();
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();

  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onBathymetricFileChange = ({ file, cellX, cellY }: { file: FileType; cellX?: number; cellY?: number }) => {
    if (!cellX || !cellY) return setInputState({ ...inputState, bathymetryFile: file });
    setInputState({ ...inputState, bathymetryFile: file, cellX: cellX, cellY: cellY, neY: cellY, neX: cellX });
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
      simulationSetup: undefined,
    });
    if (name && fileId) {
      history.push('/designTools/maris-srf');
    }
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name == 'cellX') {
      const newVal = event.target.value as any;
      setInputState({ ...inputState, cellX: newVal, neX: newVal });
      return;
    }
    if (event.target.name == 'cellY') {
      const newVal = event.target.value as any;
      setInputState({ ...inputState, cellY: newVal, neY: newVal });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              onChange={onInputChange}
              name={'project'}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'SRF'}
              modelType={ModelEnum.SRF}
              redirectLocation={'/designTools/maris-srf'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Number of cells'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.cellX}
                    onChange={onNumberChange}
                    name={'cellX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.cellY}
                    onChange={onNumberChange}
                    name={'cellY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box ml={4}>
              <LabelWrapper label={'Cell size (m)'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={0.0000000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.cellSizeX}
                    onChange={onNumberChange}
                    name={'cellSizeX'}
                    label={'DX'}
                  />
                  <CustomInputWithLabel
                    required
                    min={0.0000000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.cellSizeY}
                    onChange={onNumberChange}
                    name={'cellSizeY'}
                    label={'DY'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
        <Box>
          <LabelWrapper label={'Insert bathymetry file'}>
            <BathymetricFileContainer
              typePage={'SRF'}
              setBathymetricInputs={onBathymetricFileChange}
              requestDims={!inputState.simulationSetup}
              project={inputState.project}
              file={inputState.bathymetryFile}
            />
          </LabelWrapper>
        </Box>

        <Box>
          <Typography my={1} variant={'subtitle1'}>
            Subarea bounding points
          </Typography>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box mr={4}>
              <LabelWrapper label={'Southwestern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swX}
                    onChange={onNumberChange}
                    name={'swX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swY}
                    onChange={onNumberChange}
                    name={'swY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Northeastern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.neX}
                    onChange={onNumberChange}
                    name={'neX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.neY}
                    onChange={onNumberChange}
                    name={'neY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(SrfNumericalDomainInputsGroup);
