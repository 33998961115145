import React, { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { closeFileModal, closeHeatmapModal } from '../../redux/slices/modalSlice';
import HeatmapModal from './HeatmapModal';
import { FileEnum } from '../../models/types/FileEnum';
import { StyledFileDialog } from '../custom/StyledFileDialog';

const FileViewDialog: FC = ({ children }) => {
  const { isOpen, data } = useAppSelector((state) => state.modal.fileModal);
  const { isOpen: heatmapOpen, data: heatmapData } = useAppSelector((state) => state.modal.heatmapModal);

  const dispatch = useAppDispatch();

  const dialogContent = useMemo(() => {
    if (data?.value && data?.type) {
      if (data.type === FileEnum.IMAGE)
        return <img width={'100%'} src={data.value} height={'100%'} style={{ objectFit: 'contain' }} alt={'icon'} />;
      return <iframe width={'100%'} src={data.value} height={'100%'} />;
    }
  }, [data]);

  const heatMapModal = useMemo(() => {
    if (heatmapOpen && heatmapData)
      return <HeatmapModal open={heatmapOpen} file={heatmapData} handleClose={() => dispatch(closeHeatmapModal())} />;
  }, [heatmapOpen, heatmapData]);

  return (
    <>
      <StyledFileDialog open={isOpen} onClose={() => dispatch(closeFileModal())}>
        {dialogContent}
      </StyledFileDialog>
      {heatMapModal}

      {children}
    </>
  );
};
export default FileViewDialog;
