import React, { FC, memo } from 'react';
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadio from '../../custom/CustomRadio';
import BoxComponent from '../../custom/BoxComponent';
import { FileType } from '../../../models/inputTypes/FileType';
import { HydModelFeatures } from '../../../models/inputTypes/HydFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import RadioLabel from '../../common/RadioLabel';
import { hydModelFeaturesInitialState } from '../../../utils/initialStates/hydInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  childSpacingSmall: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  spacingJustifyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  widthStyle: {
    maxWidth: '500px',
    width: '100%',
  },
  spacingCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    width: '100%',
    // maxWidth: '1300px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  spaceBetweenStartBox: {
    width: '100%',
    // maxWidth: '1300px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexEndBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  wrap: {
    flexWrap: 'wrap',
  },
} as const;
type InputsProps = {
  projectId: string;
  inputState: HydModelFeatures;
  setInputState: (value: HydModelFeatures) => void;
};

const HydModelFeaturesInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'coriolis') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        avgLatitute: hydModelFeaturesInitialState.avgLatitute,
      });
      return;
    }
    if (e.target.name === 'wettingDrying') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        wettingDepth: hydModelFeaturesInitialState.wettingDepth,
        dryingDepth: hydModelFeaturesInitialState.dryingDepth,
      });
      return;
    }
    if (e.target.name === 'eddyViscosity') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        eddyType: hydModelFeaturesInitialState.eddyType,
        eddyCoefficient: hydModelFeaturesInitialState.eddyCoefficient,
        eddyFile: hydModelFeaturesInitialState.eddyFile,
      });
      return;
    }
    if (e.target.name === 'bottomFriction') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        bottomFrictionType: hydModelFeaturesInitialState.bottomFrictionType,
        bottomFrictionCoefficient: hydModelFeaturesInitialState.bottomFrictionCoefficient,
        frictionFile: hydModelFeaturesInitialState.frictionFile,
      });
      return;
    }
    if (e.target.name === 'vegetation') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        vegetationType: hydModelFeaturesInitialState.vegetationType,
        coef: hydModelFeaturesInitialState.coef,
        width: hydModelFeaturesInitialState.width,
        height: hydModelFeaturesInitialState.height,
        stems: hydModelFeaturesInitialState.stems,
        stemsFile: hydModelFeaturesInitialState.stemsFile,
        vegDragType: hydModelFeaturesInitialState.vegDragType,
        dragFile: hydModelFeaturesInitialState.dragFile,
      });
      return;
    }
    if (e.target.name === 'waveRadiation') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        sxxFile: hydModelFeaturesInitialState.sxxFile,
        sxyFile: hydModelFeaturesInitialState.sxyFile,
        syyFile: hydModelFeaturesInitialState.syyFile,
      });
      return;
    }
    if (e.target.name === 'windForcing') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        windForcingType: hydModelFeaturesInitialState.windForcingType,
        uWind: hydModelFeaturesInitialState.uWind,
        direction: hydModelFeaturesInitialState.direction,
        windFrictionFile: hydModelFeaturesInitialState.windFrictionFile,
        wind_friction_flag: hydModelFeaturesInitialState.wind_friction_flag,
        constant_wind_friction_factor: hydModelFeaturesInitialState.constant_wind_friction_factor,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'eddyType') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.value as any,
        eddyCoefficient: hydModelFeaturesInitialState.eddyCoefficient,
        eddyFile: hydModelFeaturesInitialState.eddyFile,
      });
      return;
    }
    if (e.target.name === 'bottomFrictionType') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.value as any,
        bottomFrictionCoefficient: hydModelFeaturesInitialState.bottomFrictionCoefficient,
        frictionFile: hydModelFeaturesInitialState.frictionFile,
      });
      return;
    }
    if (e.target.name === 'windForcingType') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.value as any,
        uWind: hydModelFeaturesInitialState.uWind,
        direction: hydModelFeaturesInitialState.direction,
        windFrictionFile: hydModelFeaturesInitialState.windFrictionFile,
      });
      return;
    }
    if (e.target.name == 'vegetationType') {
      setInputState({
        ...inputState,
        vegetationType: e.target.value as any,
        stemsFile: undefined,
        stems: hydModelFeaturesInitialState.stems,
      });
      return;
    }
    if (e.target.name === 'vegDragType') {
      setInputState({
        ...inputState,
        vegDragType: e.target.value as any,
        dragFile: undefined,
      });
      return;
    }
    if (e.target.name === 'wind_friction_flag') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.value as any,
        constant_wind_friction_factor: hydModelFeaturesInitialState.constant_wind_friction_factor,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  return (
    <Box sx={{ flexWrap: 'wrap', ...styles.childSpacing, ...styles.flexStartBox }}>
      <Box>
        <Typography variant={'subtitle1'}>Coriolis Forcing</Typography>
        <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
          <Box>
            <LabelWrapper label={'Include coriolis forcing'}>
              <Box sx={{ ...styles.spacingJustifyContainer }}>
                <CustomSwitch checked={inputState.coriolis} name={'coriolis'} onChange={onChangeSwitch} />
              </Box>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={!inputState.coriolis}>
            <LabelWrapper label={'Average latitude'}>
              <CustomInput
                required
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                onChange={onNumberChange}
                type={'number'}
                value={inputState.avgLatitute}
                name={'avgLatitute'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>

      <Box>
        <Typography variant={'subtitle1'}>Wetting and Drying Depths</Typography>
        <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
          <Box>
            <LabelWrapper label={'Include wetting and drying'}>
              <Box sx={{ ...styles.spacingJustifyContainer }}>
                <CustomSwitch checked={inputState.wettingDrying} name={'wettingDrying'} onChange={onChangeSwitch} />
              </Box>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={!inputState.wettingDrying}>
            <LabelWrapper label={'Wetting depth (m)'}>
              <CustomInput
                disabled={!inputState.wettingDrying}
                required
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                onChange={onNumberChange}
                type={'number'}
                value={inputState.wettingDepth}
                name={'wettingDepth'}
              />
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent disabled={!inputState.wettingDrying}>
            <LabelWrapper label={'Drying depth (m)'}>
              <CustomInput
                disabled={!inputState.wettingDrying}
                required
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                onChange={onNumberChange}
                type={'number'}
                value={inputState.dryingDepth}
                name={'dryingDepth'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box sx={{ ...styles.spaceBetweenBox, ...styles.widthStyle, ...styles.wrap }}>
          <Typography variant={'subtitle1'}>Eddy Viscosity</Typography>
          <Box width={'650px'} sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
            <Box>
              <LabelWrapper label={'Include eddy viscosity'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.eddyViscosity} name={'eddyViscosity'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.eddyViscosity}>
              <RadioGroup
                aria-label="spongeFriction"
                name="eddyType"
                value={inputState.eddyType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'start'}
                  value="constant"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Constant
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography variant={'subtitle2'} color={'info.main'}>
                      Varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.eddyViscosity || inputState.eddyType !== 'constant'}>
              <LabelWrapper label={'Coefficient'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  disabled={!inputState.eddyViscosity || inputState.eddyType !== 'constant'}
                  errorText={'Enter positive number'}
                  onChange={onNumberChange}
                  type={'number'}
                  value={inputState.eddyCoefficient}
                  name={'eddyCoefficient'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.eddyViscosity || inputState.eddyType !== 'varying'}>
          <LabelWrapper label={'Insert eddy file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.eddyViscosity || inputState.eddyType !== 'varying'}
              file={inputState.eddyFile}
              setFile={(file) => onFileChange(file, 'eddyFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box sx={{ ...styles.spaceBetweenBox, ...styles.widthStyle, ...styles.wrap }}>
          <Typography variant={'subtitle1'}>Bottom Friction</Typography>
          <Box width={'650px'} sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
            <Box>
              <LabelWrapper label={'Include bottom friction'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.bottomFriction} name={'bottomFriction'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.bottomFriction}>
              <RadioGroup
                aria-label="btmFrictionRadio"
                name="bottomFrictionType"
                value={inputState.bottomFrictionType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'start'}
                  value="constant"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Constant
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography variant={'subtitle2'} color={'info.main'}>
                      Varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.bottomFriction || inputState.bottomFrictionType !== 'constant'}>
              <LabelWrapper label={'Coefficient'}>
                <CustomInput
                  min={0.0000000001}
                  required
                  step={'any'}
                  disabled={!inputState.bottomFriction || inputState.bottomFrictionType !== 'constant'}
                  errorText={'Enter positive number'}
                  onChange={onNumberChange}
                  type={'number'}
                  value={inputState.bottomFrictionCoefficient}
                  name={'bottomFrictionCoefficient'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.bottomFriction || inputState.bottomFrictionType !== 'varying'}>
          <LabelWrapper label={'Insert friction file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.bottomFriction || inputState.bottomFrictionType !== 'varying'}
              file={inputState.frictionFile}
              setFile={(file) => onFileChange(file, 'frictionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography variant={'subtitle1'}>Vegetation</Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box width={'100px'} sx={{ ...styles.wrap }}>
          <LabelWrapper label={'Include vegetation'}>
            <Box sx={{ ...styles.spacingCenterContainer }}>
              <CustomSwitch checked={inputState.vegetation} name={'vegetation'} onChange={onChangeSwitch} />
            </Box>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Calibration coefficient'}>
            <CustomInput
              disabled={!inputState.vegetation}
              min={0.00000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              value={inputState.coef}
              name={'coef'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Stem width (m)'}>
            <CustomInput
              disabled={!inputState.vegetation}
              min={0.00000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              value={inputState.width}
              name={'width'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Stem height (m)'}>
            <CustomInput
              disabled={!inputState.vegetation}
              min={0.00000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              value={inputState.height}
              name={'height'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <BoxComponent mr={3} width={'80px'} disabled={!inputState.vegetation}>
          <RadioGroup
            aria-label="vegetationRatio"
            name="vegetationType"
            value={inputState.vegetationType}
            onChange={onChangeRadio}
          >
            <FormControlLabel
              labelPlacement={'start'}
              value="constant"
              control={<CustomRadio />}
              label={
                <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                  Constant
                </Typography>
              }
            />
            <FormControlLabel
              labelPlacement={'start'}
              value="varying"
              control={<CustomRadio />}
              label={
                <Typography variant={'subtitle2'} color={'info.main'}>
                  Varying
                </Typography>
              }
            />
          </RadioGroup>
        </BoxComponent>
        <BoxComponent width={'80px'} disabled={inputState.vegetationType !== 'constant'}>
          <LabelWrapper label={'Stems / m\u00B2'}>
            <CustomInput
              disabled={!inputState.vegetation || inputState.vegetationType !== 'constant'}
              min={0.00000001}
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              required
              value={inputState.stems}
              name={'stems'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={!inputState.vegetation || inputState.vegetationType !== 'varying'}>
          <LabelWrapper label={'Insert Stems / m\u00B2 file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.vegetation || inputState.vegetationType !== 'varying'}
              file={inputState.stemsFile}
              setFile={(file) => onFileChange(file, 'stemsFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography my={2} variant={'subtitle1'}>
        Vegetation drag coefficient
      </Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <BoxComponent width={'100px'} sx={{ ...styles.spacingJustifyContainer }} disabled={!inputState.vegetation}>
          <RadioGroup
            aria-label="vegetationDragRatio"
            name="vegDragType"
            value={inputState.vegDragType}
            onChange={onChangeRadio}
          >
            <FormControlLabel
              labelPlacement={'start'}
              value="parametric"
              control={<CustomRadio />}
              label={
                <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                  Parametric
                </Typography>
              }
            />
            <FormControlLabel
              labelPlacement={'start'}
              value="varying"
              control={<CustomRadio />}
              label={
                <Typography variant={'subtitle2'} color={'info.main'}>
                  Varying
                </Typography>
              }
            />
          </RadioGroup>
        </BoxComponent>
        <BoxComponent disabled={inputState.vegDragType !== 'parametric'}>
          <LabelWrapper label={'A'}>
            <CustomInput
              disabled={!inputState.vegetation || inputState.vegDragType !== 'parametric'}
              min={0}
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              required
              value={inputState.a}
              name={'a'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={inputState.vegDragType !== 'parametric'}>
          <LabelWrapper label={'B'}>
            <CustomInput
              disabled={!inputState.vegetation || inputState.vegDragType !== 'parametric'}
              min={0}
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              required
              value={inputState.b}
              name={'b'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={inputState.vegDragType !== 'parametric'}>
          <LabelWrapper label={'C'}>
            <CustomInput
              disabled={!inputState.vegetation || inputState.vegDragType !== 'parametric'}
              min={0}
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              required
              value={inputState.c}
              name={'c'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </BoxComponent>
        <Box width={'80px'}></Box>
        <Box width={'80px'}></Box>
        <BoxComponent disabled={!inputState.vegetation || inputState.vegDragType !== 'varying'}>
          <LabelWrapper label={'Insert drag coefficient file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.vegetation || inputState.vegDragType !== 'varying'}
              file={inputState.dragFile}
              setFile={(file) => onFileChange(file, 'dragFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box>
          <Typography mb={1} variant={'subtitle1'}>
            Wave Radiation
          </Typography>
          <Box>
            <LabelWrapper label={'Include wave radiation'}>
              <Box sx={{ ...styles.spacingJustifyContainer }}>
                <CustomSwitch checked={inputState.waveRadiation} name={'waveRadiation'} onChange={onChangeSwitch} />
              </Box>
            </LabelWrapper>
          </Box>
        </Box>
      </Box>

      <BoxComponent
        disabled={!inputState.waveRadiation}
        sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}
      >
        <Box mt={1}>
          <LabelWrapper label={'Insert sxx file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.waveRadiation}
              file={inputState.sxxFile}
              setFile={(file) => onFileChange(file, 'sxxFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert sxy file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.waveRadiation}
              file={inputState.sxyFile}
              setFile={(file) => onFileChange(file, 'sxyFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert syy file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.waveRadiation}
              file={inputState.syyFile}
              setFile={(file) => onFileChange(file, 'syyFile')}
            />
          </LabelWrapper>
        </Box>
      </BoxComponent>

      {/* <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box sx={{ ...styles.spaceBetweenBox, ...styles.widthStyle, ...styles.wrap }}>
          <Typography variant={'subtitle1'}>Wind Forcing</Typography>
          <Box width={'1800px'} sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
            <Box>
              <LabelWrapper label={'Include wind forcing'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.windForcing} name={'windForcing'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent mr={4} disabled={!inputState.windForcing}>
              <RadioGroup
                aria-label="windForcingType"
                name="windForcingType"
                value={inputState.windForcingType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'start'}
                  value="constant"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Constant
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography variant={'subtitle2'} color={'info.main'}>
                      Temporally varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}>
              <Box sx={{ ...styles.flexStartBox }}>
                <Box>
                  <LabelWrapper label={'Uwind (m/s)'}>
                    <CustomInput
                      min={0.0000000001}
                      required
                      step={'any'}
                      disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}
                      errorText={'Enter positive number'}
                      maxWidth={'65px'}
                      onChange={onNumberChange}
                      type={'number'}
                      value={inputState.uWind}
                      name={'uWind'}
                    />
                  </LabelWrapper>
                </Box>
                <Box>
                  <LabelWrapper label={'Direction (degrees)'}>
                    <CustomInput
                      min={0}
                      // max={360}
                      required
                      step={'any'}
                      disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}
                      errorText={'Enter positive number'}
                      maxWidth={'65px'}
                      onChange={onNumberChange}
                      type={'number'}
                      value={inputState.direction}
                      name={'direction'}
                    />
                  </LabelWrapper>
                </Box>
              </Box>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.windForcing || inputState.windForcingType !== 'varying'}>
          <LabelWrapper label={'Insert wind forcing file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.windForcing || inputState.windForcingType !== 'varying'}
              file={inputState.windFrictionFile}
              setFile={(file) => onFileChange(file, 'windFrictionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box> */}

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box>
          <Typography variant={'subtitle1'}>Wind Forcing</Typography>
          <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Include wind forcing'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.windForcing} name={'windForcing'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent width={'180px'} disabled={!inputState.windForcing}>
              <RadioGroup
                aria-label="windForcingType"
                name="windForcingType"
                value={inputState.windForcingType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'start'}
                  value="constant"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Constant
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography variant={'subtitle2'} color={'info.main'}>
                      Temporally varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}>
              <LabelWrapper label={'Uwind (m/s)'}>
                <CustomInput
                  min={0.0000000001}
                  required
                  step={'any'}
                  disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}
                  errorText={'Enter positive number'}
                  // maxWidth={'65px'}
                  onChange={onNumberChange}
                  type={'number'}
                  value={inputState.uWind}
                  name={'uWind'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}>
              <LabelWrapper label={'Direction (degrees)'}>
                <CustomInput
                  min={0}
                  // max={360}
                  required
                  step={'any'}
                  disabled={!inputState.windForcing || inputState.windForcingType !== 'constant'}
                  errorText={'Enter positive number'}
                  // maxWidth={'65px'}
                  onChange={onNumberChange}
                  type={'number'}
                  value={inputState.direction}
                  name={'direction'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.windForcing || inputState.windForcingType !== 'varying'}>
          <LabelWrapper label={'Insert wind forcing file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.windForcing || inputState.windForcingType !== 'varying'}
              file={inputState.windFrictionFile}
              setFile={(file) => onFileChange(file, 'windFrictionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <BoxComponent disabled={!inputState.windForcing}>
        <Typography my={2} variant={'subtitle1'}>
          Wind Friction Factor
        </Typography>
        <Box sx={{ ...styles.wrap, ...styles.flexStartBox }}>
          <BoxComponent disabled={!inputState.windForcing} mr={3}>
            <RadioGroup
              aria-label="windForcingType"
              name="wind_friction_flag"
              value={inputState.wind_friction_flag}
              onChange={onChangeRadio}
            >
              <FormControlLabel
                labelPlacement={'start'}
                value="1"
                control={<CustomRadio />}
                label={
                  <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                    Constant
                  </Typography>
                }
              />
              <FormControlLabel
                labelPlacement={'start'}
                value="2"
                control={<CustomRadio />}
                label={
                  <Typography variant={'subtitle2'} color={'info.main'}>
                    Automatic Calculation
                  </Typography>
                }
              />
            </RadioGroup>
          </BoxComponent>
          <BoxComponent disabled={inputState.wind_friction_flag == '2'}>
            <Box sx={{ ...styles.flexStartBox }}>
              <Box>
                <LabelWrapper label={'Cd'}>
                  <CustomInput
                    min={0.00000000000001}
                    required
                    step={'any'}
                    disabled={inputState.wind_friction_flag == '2' || !inputState.windForcing}
                    errorText={'Enter positive number'}
                    maxWidth={'65px'}
                    onChange={onNumberChange}
                    type={'number'}
                    value={inputState.constant_wind_friction_factor}
                    name={'constant_wind_friction_factor'}
                  />
                </LabelWrapper>
              </Box>
            </Box>
          </BoxComponent>
        </Box>
      </BoxComponent>
    </Box>
  );
};

export default memo(HydModelFeaturesInputsGroup);
