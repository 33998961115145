import React, { FC, memo, useMemo, useState } from 'react';
import { Checkbox, CircularProgress, Grid, IconButton, TableCell, TableRow, Typography, Box } from '@mui/material';
import CustomTable from '../../custom/CustomTable';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/editRow.svg';
import ConfirmationModal from '../../dialogs/ConfirmationModal';
import {
  useChainProjectFileMutation,
  useDeleteProjectFileMutation,
  useGetProjectFileCustomMutation,
} from '../../../redux/RTK/mutations/projectFileMutations';
import { useGetProjectFilesQuery } from '../../../redux/RTK/queries/projectFileQuery';
import moment from 'moment-timezone';
import { findFileType } from '../../../utils/functions/findFileType';
import { sortProjectFiles } from '../../../utils/functions/sortProjectFiles';
import Dot from '../../common/Dot';
import { downloadAxiosFile } from '../../../utils/functions/downloadFile';
import { useHistory } from 'react-router';
import { openErrorToast } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../redux/store';
import createRedirectLink from '../../../utils/functions/createRedirectLink';
import { formatBytes } from '../../../utils/functions/formatBytes';
import { asyncGetFilePreview, openHeatmapModal } from '../../../redux/slices/modalSlice';
import { ReactComponent as ViewIcon } from '../../../assets/images/view.svg';
import { styled } from '@mui/material/styles';
import { useUserQuery } from '../../../redux/RTK/queries/userQuery';
import { useStorageQuery } from '../../../redux/RTK/queries/storageQuery';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 40,
  width: 14,
  height: 14,
  border: '2px solid #B6B6B6',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#394b59',
  border: '2px solid #394b59',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#30404d',
  },
});

const disabledButton = {
  '&:disabled': {
    opacity: '0.2',
  },
};

const TableHead = [
  { title: 'NAME', align: 'flex-start', sort: true },
  { title: 'DATE', align: 'center', sort: true },
  { title: 'SIZE', align: 'center', sort: true },
  { title: 'TYPE', align: 'center', sort: true },
  { title: 'ACTIONS', align: 'center' },
];

type CreateProjectGroupProps = {
  debouncedSearch: string;
  filePerProject: string;
};

const ProjectsFilesGroup: FC<CreateProjectGroupProps> = ({ filePerProject, debouncedSearch }) => {
  const history = useHistory();
  const [deleteProjectFile] = useDeleteProjectFileMutation();
  const dispatch = useAppDispatch();
  const [chainProjectFile] = useChainProjectFileMutation();
  const [getFileData] = useGetProjectFileCustomMutation();
  const { data: storage } = useStorageQuery({});
  const { data: rawData, isFetching } = useGetProjectFilesQuery(filePerProject, { refetchOnMountOrArgChange: 2 });
  const [deleteFile, setDeleteFile] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState({ title: '', ascending: false });

  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);

  const handleConfirmationClose = () => {
    setDeleteFile([]);
  };

  const dataUtc = useMemo(() => {
    if (storage && storage.profile) {
      const utc = storage.profile.timezone;
      if (utc) {
        return utc.slice(utc.indexOf('C') + 1, utc.lastIndexOf(')'));
      } else return '';
    } else return '';
  }, [storage]);

  const data = useMemo(() => {
    if (rawData?.length && debouncedSearch)
      return rawData.filter((item) => item.name.toLowerCase().includes(debouncedSearch.toLowerCase()));
    return rawData;
  }, [rawData, debouncedSearch]);

  const onConfirmationDeleteClick = async () => {
    if (deleteFile) {
      try {
        const promises = deleteFile.map((n) => deleteProjectFile({ projectId: filePerProject, fileName: n }).unwrap());
        await Promise.all(promises);
      } catch (e) {
        dispatch(openErrorToast('Error deleting files. Please try again!'));
      }
      setSelectedProjectsIds([]);
      setDeleteFile([]);
    }
  };

  const toggleSelectProject = (nameProject: string) => {
    const projectIdsCopy = [...selectedProjectsIds];
    if (!projectIdsCopy.includes(nameProject)) {
      setSelectedProjectsIds([...projectIdsCopy, nameProject]);
    } else {
      setSelectedProjectsIds(projectIdsCopy.filter((_name) => _name !== nameProject));
    }
  };

  const tableRows = useMemo(() => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <CircularProgress color={'info'} />
          </TableCell>
        </TableRow>
      );
    }

    if (!data?.length) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <Typography my={1} variant={'subtitle1'}>
              No available files
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    if (data?.length) {
      const sortedFiles = sortProjectFiles(data.slice(), sortBy.title, sortBy.ascending);
      return sortedFiles.map((item, i: number) => {
        const typeCategory = findFileType(item.name.toLowerCase());
        let type = '';
        if (item.simulation !== null) {
          type = item.simulation.toUpperCase();
        }

        const isViewable = !item.name.endsWith('.json') && !item.name.endsWith('.csv');
        const isDisable = item.name.endsWith('.dxf');

        return (
          <TableRow key={i}>
            <TableCell>
              <Grid container alignItems={'center'}>
                <Dot type={typeCategory} mr={1.5} />
                <Typography variant={'subtitle2'}>{item.name}</Typography>
              </Grid>
            </TableCell>
            <TableCell align={'center'}>
              <Typography variant={'subtitle2'}>
                {moment(item.created_at).zone(dataUtc).format('DD/MM/yyyy HH:mm')}
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography variant={'subtitle2'}>{formatBytes(item.size, item.size.length)}</Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography variant={'subtitle2'}>{type ? typeCategory + '_' + type : typeCategory}</Typography>
            </TableCell>
            <TableCell align={'center'} sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
              <Box>
                <IconButton
                  sx={{ ...disabledButton }}
                  onClick={() => {
                    if (item.name.endsWith('.json')) return getJsonData(item);
                    if (item.name.endsWith('.csv')) return dispatch(openHeatmapModal(item));
                    return dispatch(asyncGetFilePreview({ project: filePerProject, fileName: item.name }));
                  }}
                  disabled={isDisable}
                >
                  {isViewable ? <ViewIcon /> : <EditIcon />}
                </IconButton>
                <IconButton onClick={() => downloadAxiosFile(filePerProject, [item.name])}>
                  <DownloadIcon />
                </IconButton>
                <IconButton onClick={() => setDeleteFile([item.name])}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <Checkbox
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                onChange={() => toggleSelectProject(item.name)}
                checked={selectedProjectsIds?.includes(item.name)}
              />
            </TableCell>
          </TableRow>
        );
      });
    }
  }, [data, isFetching, sortBy, selectedProjectsIds]);

  const getJsonData = async (item: any) => {
    try {
      const resMutation: any = await chainProjectFile({
        projectId: item.project.toString(),
        fileName: item.name,
        file: item.file,
      });
      if (resMutation.data.status === 'ok') {
        const res: any = await getFileData({ projectId: filePerProject.toString(), fileName: item.name });
        if (res?.data?.simulation) {
          const redirectionLink = createRedirectLink(res.data.simulation);
          history.push(`/${redirectionLink}/${filePerProject}/${item.name}/${item.uuid}`);
        }
      }
    } catch (e) {
      dispatch(openErrorToast('Error opening simulation file'));
    }
  };

  return (
    <>
      <CustomTable
        filePerProject={filePerProject}
        setDeleteFile={() => setDeleteFile(selectedProjectsIds)}
        selectProjectIds={selectedProjectsIds}
        sortBy={sortBy}
        onSortClick={(val) => setSortBy(val)}
        header={TableHead}
      >
        {tableRows}
      </CustomTable>
      <ConfirmationModal
        open={deleteFile.length !== 0}
        handleClose={handleConfirmationClose}
        message={deleteFile ? 'Are you sure you want to delete ' + deleteFile.map((name) => name) + '?' : ''}
        handleAccept={onConfirmationDeleteClick}
      />
    </>
  );
};

export default memo(ProjectsFilesGroup);
