import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Collapse, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import PmsNumericalDomainInputsGroup from '../../components/groups/pms/PmsNumericalDomainInputsGroup';
import PmsBoundaryConditionsInputsGroup from '../../components/groups/pms/PmsBoundaryConditionsInputsGroup';
import PmsModelFeaturesInputsGroup from '../../components/groups/pms/PmsModelFeaturesInputsGroup';
import EnergyDissipationInputsGroup from '../../components/groups/pms/EnergyDissipationInputsGroup';
import PmsOutputInputsGroup from '../../components/groups/pms/PmsOutputInputsGroup';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import PmsPlotAreasInputsGroup from '../../components/groups/pms/PmsPlotAreasInputsGroup';
import CustomTabs from '../../components/custom/CustomTabs';
import PmsPlotLineInputsGroup from '../../components/groups/pms/PmsPlotLineInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import {
  PlotArea,
  PmsBoundaryConditions,
  PmsDissipation,
  PmsModelFeatures,
  PmsNumericDomain,
  PmsOutput,
  PmsSubmission,
} from '../../models/inputTypes/PmsFields';
import {
  plotAreasInitialState,
  pmsBoundaryConditionsInitialState,
  pmsDissipationInitialState,
  pmsModelFeaturesInitialState,
  pmsNumericDomainInitialState,
  pmsOutputInitialState,
  pmsSubmissionInitialState,
} from '../../utils/initialStates/pmsInputStates';
import { plotTabPreparation } from '../../utils/functions/PlotTabPreparation';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { pmsPreparation } from '../../utils/modelPreparation/pmsPreparation';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { FileType } from '../../models/inputTypes/FileType';
import { PmsSimulationPreparationResponse } from '../../utils/simulationFileToState/pmsSimulationPreparation';
import { HmsSimulationPreparationResponse } from '../../utils/simulationFileToState/hmsSimulationPreparation';
import { useAppDispatch } from '../../redux/store';
import { hmsPlotAreasInitialState } from '../../utils/initialStates/hmsInputState';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';

const MarisPmsPage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const [createJob] = useCreateJobsMutation();
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [selectedPlots, setSelectedPlots] = useState<{ line: number; area: number }>({ line: 0, area: 0 });

  const [numericalState, setNumericalState] = useState<PmsNumericDomain>(pmsNumericDomainInitialState);
  const [boundaryState, setBoundaryState] = useState<PmsBoundaryConditions>(pmsBoundaryConditionsInitialState);
  const [modelState, setModelState] = useState<PmsModelFeatures>(pmsModelFeaturesInitialState);
  const [dissipationState, setDissipationState] = useState<PmsDissipation>(pmsDissipationInitialState);
  const [outputState, setOutputState] = useState<PmsOutput>(pmsOutputInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const [areaInitial, setAreaInitial] = useState<PlotArea>(plotAreasInitialState);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.PMS);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const hasNumChanged = numericalState != pmsNumericDomainInitialState;
      const hasBoundChanged = boundaryState != pmsBoundaryConditionsInitialState;
      const hasModChanged = modelState != pmsModelFeaturesInitialState;
      const hasDissChanged = dissipationState != pmsDissipationInitialState;
      const hasOutChanged = outputState != pmsOutputInitialState;
      const hasSubChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(
        hasBoundChanged || hasNumChanged || hasModChanged || hasSubChanged || hasDissChanged || hasOutChanged,
      );
    }
  }, [numericalState, boundaryState, modelState, dissipationState, outputState, submissionState]);

  useEffect(() => {
    setAreaInitial({
      ...areaInitial,
      ne_x: numericalState.cellX ?? hmsPlotAreasInitialState.ne_x,
      ne_y: numericalState.cellY ?? hmsPlotAreasInitialState.ne_y,
    });
  }, [numericalState.cellX, numericalState.cellY]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const plotAreaTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotAreas, 'area');
  }, [outputState.plotAreas]);

  const plotLineTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotLines, 'line');
  }, [outputState.plotLines]);

  const onLineChange = (event: any, newValue: number) => {
    setSelectedPlots({ ...selectedPlots, [event.currentTarget.id]: newValue });
  };

  const onNoOutputAccept = () => {
    const res = pmsPreparation(
      numericalState,
      boundaryState,
      modelState,
      dissipationState,
      outputState,
      submissionState,
    );
    setSaveNoOutput(false);
    if (res) createJob(res);
  };

  const onSubmitClick = (e: FormEvent<any>) => {
    e.preventDefault();
    if (!outputState.plotAreas && !outputState.plotLines) {
      setSaveNoOutput(true);
      return;
    }
    const res = pmsPreparation(
      numericalState,
      boundaryState,
      modelState,
      dissipationState,
      outputState,
      submissionState,
    );
    if (res) createJob(res);
  };

  const onSimulationSave = () => {
    const preparedValue = pmsPreparation(
      numericalState,
      boundaryState,
      modelState,
      dissipationState,
      outputState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (
    sim: PmsSimulationPreparationResponse | HmsSimulationPreparationResponse,
    file?: FileType,
  ) => {
    const value = sim as PmsSimulationPreparationResponse;
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setBoundaryState(value.boundary);
    setModelState(value.modelFeature);
    setDissipationState(value.energyDissipation);
    setOutputState(value.output);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'Maris PMS'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/maris-pms' ? true : 'Changes that you made may not be saved.'
        }
      />

      <Typography variant={'h4'}>Numerical Domain</Typography>
      <form onSubmit={onSubmitClick}>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Boundary Conditions
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsBoundaryConditionsInputsGroup inputState={boundaryState} setInputState={setBoundaryState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsModelFeaturesInputsGroup inputState={modelState} setInputState={setModelState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Energy Dissipation
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <EnergyDissipationInputsGroup
            projectId={numericalState.project}
            inputState={dissipationState}
            setInputState={setDissipationState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Output
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsOutputInputsGroup inputState={outputState} setInputState={setOutputState} areaInitial={areaInitial} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Plot Areas
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotAreas !== 0} collapsedSize={1}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.area}
              name={'area'}
              onChange={onLineChange}
            >
              {plotAreaTabs}
            </CustomTabs>
            {outputState.plotAreaValues[selectedPlots.area] ? (
              <PmsPlotAreasInputsGroup
                inputState={outputState.plotAreaValues[selectedPlots.area]}
                setInputState={(newArea) => {
                  const newPlotArray = outputState.plotAreaValues.slice();
                  newPlotArray[selectedPlots.area] = { ...newPlotArray[selectedPlots.area], ...newArea };
                  setOutputState({ ...outputState, plotAreaValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Plot Lines
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotLines !== 0} collapsedSize={5}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.line}
              name={'line'}
              onChange={onLineChange}
            >
              {plotLineTabs}
            </CustomTabs>
            {outputState.plotLineValues[selectedPlots.line] ? (
              <PmsPlotLineInputsGroup
                inputState={outputState.plotLineValues[selectedPlots.line]}
                setInputState={(newLine) => {
                  const newPlotArray = outputState.plotLineValues.slice();
                  newPlotArray[selectedPlots.line] = { ...newPlotArray[selectedPlots.line], ...newLine };
                  setOutputState({ ...outputState, plotLineValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>

      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisPmsPage;
