import React, { FC, memo, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { HydNumericalDomain } from '../../../models/inputTypes/HydFields';
import CustomInput from '../../custom/CustomInput';
import { HydSimulationPreparationResponse } from '../../../utils/simulationFileToState/hydSimulationPreparation';
import { useHistory, useParams } from 'react-router';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type HydNumericalDomainInputsGroupProps = {
  inputState: HydNumericalDomain;
  setInputState: (value: HydNumericalDomain) => void;
  simulationChangeFile?: (value: HydSimulationPreparationResponse, file?: FileType) => void;
};

const HydNumericalDomainInputsGroup: FC<HydNumericalDomainInputsGroupProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const history = useHistory();
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (event: SelectChangeEvent<any> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name == 'project') {
      setInputState({ ...inputState, project: event.target.value, simulationSetup: undefined });
      if (name && fileId) {
        history.push(`/numerical-models/maris-hyd`);
      }
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name == 'cellX') {
      const newVal = event.target.value as any;
      setInputState({ ...inputState, cellX: newVal, neX: newVal });
      return;
    }
    if (event.target.name == 'cellY') {
      const newVal = event.target.value as any;
      setInputState({ ...inputState, cellY: newVal, neY: newVal });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onBathymetricFileChange = ({ file, cellX, cellY }: { file: FileType; cellX?: number; cellY?: number }) => {
    if (!cellX || !cellY) return setInputState({ ...inputState, bathymetryFile: file });
    setInputState({ ...inputState, bathymetryFile: file, cellX: cellX, cellY: cellY, neY: cellY, neX: cellX });
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              onChange={onInputChange}
              name={'project'}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'HYD'}
              modelType={ModelEnum.HYD}
              redirectLocation={'/numerical-models/maris-hyd'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Number of cells'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.cellX}
                    onChange={onNumberChange}
                    name={'cellX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.cellY}
                    onChange={onNumberChange}
                    name={'cellY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box ml={4}>
              <LabelWrapper label={'Cell size (m)'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={0.0000000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.cellSizeX}
                    onChange={onNumberChange}
                    name={'cellSizeX'}
                    label={'DX'}
                  />
                  <CustomInputWithLabel
                    required
                    min={0.0000000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.cellSizeY}
                    onChange={onNumberChange}
                    name={'cellSizeY'}
                    label={'DY'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
        <Box>
          <LabelWrapper label={'Insert bathymetry file'}>
            <BathymetricFileContainer
              typePage={'HYD'}
              requestDims={!inputState.simulationSetup}
              setBathymetricInputs={onBathymetricFileChange}
              project={inputState.project}
              file={inputState.bathymetryFile}
            />
          </LabelWrapper>
        </Box>

        <Box>
          <Typography my={1} variant={'subtitle1'}>
            Subarea bounding points
          </Typography>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box mr={4}>
              <LabelWrapper label={'Southwestern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swX}
                    onChange={onNumberChange}
                    name={'swX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swY}
                    onChange={onNumberChange}
                    name={'swY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Northeastern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={inputState.swX}
                    step={1}
                    errorText={
                      valueX < inputState.swX && valueX > 0
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    value={inputState.neX}
                    onChange={onInputChangeX}
                    name={'neX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={inputState.swY}
                    step={1}
                    errorText={
                      valueY < inputState.swY && valueY > 0
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    value={inputState.neY}
                    onChange={onInputChangeY}
                    name={'neY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
          <Box mt={2}>
            <LabelWrapper label={'Still Water Level Shift (m)'}>
              <CustomInput
                required
                maxWidth={'150px'}
                step={'any'}
                errorText={'Enter number'}
                type={'number'}
                onChange={onInputChange}
                value={inputState.waterLevel}
                name={'waterLevel'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(HydNumericalDomainInputsGroup);
