import React, { FC, memo } from 'react';
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadio from '../../custom/CustomRadio';
import BoxComponent from '../../custom/BoxComponent';
import { FileType } from '../../../models/inputTypes/FileType';
import { HmsEnergyDissipation } from '../../../models/inputTypes/HmsFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { checkPositiveNumber } from '../../../utils/functions/checkPositiveNumber';
import { hmsEnergyDissipationInitialState } from '../../../utils/initialStates/hmsInputState';
import RadioLabel from '../../common/RadioLabel';

const styles = {
  childSpacingSmall: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 1,
    },
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  spacingJustifyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
  },
  spaceBetweenBox: {
    width: '100%',
    // maxWidth: '1100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  spacingCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexEndBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
} as const;
type InputsProps = {
  projectId: string;

  inputState: HmsEnergyDissipation;
  setInputState: (value: HmsEnergyDissipation) => void;
};
const HmsEnergyDissipationInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == 'waveBreaking') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        a: hmsEnergyDissipationInitialState.a,
        y1: hmsEnergyDissipationInitialState.y1,
        y2: hmsEnergyDissipationInitialState.y2,
      });
      return;
    }
    if (e.target.name == 'bottomFriction') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        bottomFrictionType: hmsEnergyDissipationInitialState.bottomFrictionType,
        coefficient: hmsEnergyDissipationInitialState.coefficient,
        frictionFile: hmsEnergyDissipationInitialState.frictionFile,
      });
      return;
    }
    if (e.target.name === 'vegetation') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        vegetationType: hmsEnergyDissipationInitialState.vegetationType,
        coef: hmsEnergyDissipationInitialState.coef,
        width: hmsEnergyDissipationInitialState.width,
        height: hmsEnergyDissipationInitialState.height,
        stems: hmsEnergyDissipationInitialState.stems,
        stemsFile: hmsEnergyDissipationInitialState.stemsFile,
      });
      return;
    }
    if (e.target.name == 'spongeLayer') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        spongeLayerType: hmsEnergyDissipationInitialState.spongeLayerType,
        west: hmsEnergyDissipationInitialState.west,
        north: hmsEnergyDissipationInitialState.north,
        east: hmsEnergyDissipationInitialState.east,
        south: hmsEnergyDissipationInitialState.south,
        spongeFile: hmsEnergyDissipationInitialState.spongeFile,
      });
      return;
    }
    if (e.target.name == 'eddyLayer') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        eddyType: hmsEnergyDissipationInitialState.eddyType,
        eddyCoefficient: hmsEnergyDissipationInitialState.eddyCoefficient,
        eddyFile: hmsEnergyDissipationInitialState.eddyFile,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == 'bottomFrictionType') {
      setInputState({
        ...inputState,
        bottomFrictionType: e.target.value as any,
        coefficient: hmsEnergyDissipationInitialState.coefficient,
        frictionFile: hmsEnergyDissipationInitialState.frictionFile,
      });
      return;
    }
    if (e.target.name == 'vegetationType') {
      setInputState({
        ...inputState,
        vegetationType: e.target.value as any,
        stemsFile: undefined,
        stems: hmsEnergyDissipationInitialState.stems,
      });
      return;
    }
    if (e.target.name == 'spongeLayerType') {
      setInputState({
        ...inputState,
        spongeLayerType: e.target.value as any,
        west: hmsEnergyDissipationInitialState.west,
        north: hmsEnergyDissipationInitialState.north,
        east: hmsEnergyDissipationInitialState.east,
        south: hmsEnergyDissipationInitialState.south,
        spongeFile: hmsEnergyDissipationInitialState.spongeFile,
      });
      return;
    }
    if (e.target.name == 'eddyType') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.value as any,
        eddyCoefficient: hmsEnergyDissipationInitialState.eddyCoefficient,
        eddyFile: hmsEnergyDissipationInitialState.eddyFile,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  return (
    <Box sx={{ flexWrap: 'wrap', ...styles.childSpacing, ...styles.flexStartBox }}>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box>
          <Typography variant={'subtitle1'}>Wave Breaking</Typography>
          <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Include wave breaking'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.waveBreaking} name={'waveBreaking'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.waveBreaking}>
              <LabelWrapper label={'a'}>
                <CustomInput
                  error={checkPositiveNumber(inputState.a)}
                  errorText={'Enter positive number'}
                  value={inputState.a}
                  name={'a'}
                  step={'any'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.waveBreaking}>
              <LabelWrapper label={'y1'}>
                <CustomInput
                  error={checkPositiveNumber(inputState.y1)}
                  errorText={'Enter positive number'}
                  value={inputState.y1}
                  name={'y1'}
                  step={'any'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.waveBreaking}>
              <LabelWrapper label={'y2'}>
                <CustomInput
                  error={checkPositiveNumber(inputState.y2)}
                  errorText={'Enter positive number'}
                  value={inputState.y2}
                  name={'y2'}
                  step={'any'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <Box>
              <Typography variant={'subtitle1'}>Bottom Friction</Typography>
              <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
                <Box>
                  <LabelWrapper label={'Include bottom friction'}>
                    <Box sx={{ ...styles.spacingJustifyContainer }}>
                      <CustomSwitch
                        checked={inputState.bottomFriction}
                        name={'bottomFriction'}
                        onChange={onChangeSwitch}
                      />
                    </Box>
                  </LabelWrapper>
                </Box>
                <BoxComponent disabled={!inputState.bottomFriction}>
                  <RadioGroup
                    aria-label="btmFriction"
                    name="bottomFrictionType"
                    value={inputState.bottomFrictionType}
                    onChange={onChangeRadio}
                  >
                    <FormControlLabel
                      labelPlacement={'start'}
                      value="constant"
                      control={<CustomRadio />}
                      label={
                        <Typography variant={'subtitle2'} color={'info.main'}>
                          Constant
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      labelPlacement={'start'}
                      value="varying"
                      control={<CustomRadio />}
                      label={
                        <Typography variant={'subtitle2'} color={'info.main'}>
                          Varying
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </BoxComponent>
                <BoxComponent disabled={!inputState.bottomFriction || inputState.bottomFrictionType === 'varying'}>
                  <LabelWrapper label={'Wave friction factor'}>
                    <CustomInput
                      disabled={!inputState.bottomFriction || inputState.bottomFrictionType === 'varying'}
                      min={0.0000000001}
                      required
                      step={'any'}
                      errorText={'Enter positive number'}
                      value={inputState.coefficient}
                      name={'coefficient'}
                      type={'number'}
                      onChange={onNumberChange}
                    />
                  </LabelWrapper>
                </BoxComponent>
              </Box>
            </Box>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.bottomFriction || inputState.bottomFrictionType !== 'varying'}>
          <LabelWrapper label={'Insert friction file'}>
            <ButtonFileDialog
              typePage={'HMS'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.bottomFriction || inputState.bottomFrictionType !== 'varying'}
              file={inputState.frictionFile}
              setFile={(file) => onFileChange(file, 'frictionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography variant={'subtitle1'}>Attenuation due to vegetation</Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap, ...styles.childSpacingSmall }}>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box sx={{ ...styles.wrap }}>
            <LabelWrapper label={'Include vegetation'}>
              <Box sx={{ ...styles.spacingCenterContainer }}>
                <CustomSwitch checked={inputState.vegetation} name={'vegetation'} onChange={onChangeSwitch} />
              </Box>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={!inputState.vegetation} sx={{ ...styles.flexStartBox }}>
            <Box mr={3}>
              <LabelWrapper label={'Calibration coefficient'}>
                <CustomInput
                  disabled={!inputState.vegetation}
                  min={0.00000001}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.coef}
                  name={'coef'}
                  onChange={onNumberChange}
                />
              </LabelWrapper>
            </Box>
            <Box mr={3}>
              <LabelWrapper label={'Stem width (m)'}>
                <CustomInput
                  disabled={!inputState.vegetation}
                  min={0.00000001}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.width}
                  name={'width'}
                  onChange={onNumberChange}
                />
              </LabelWrapper>
            </Box>
            <Box mr={3}>
              <LabelWrapper label={'Stem height (m)'}>
                <CustomInput
                  disabled={!inputState.vegetation}
                  min={0.00000001}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.height}
                  name={'height'}
                  onChange={onNumberChange}
                />
              </LabelWrapper>
            </Box>
          </BoxComponent>
          <BoxComponent disabled={!inputState.vegetation}>
            <RadioGroup
              aria-label="vegetationRatio"
              name="vegetationType"
              value={inputState.vegetationType}
              onChange={onChangeRadio}
            >
              <FormControlLabel
                labelPlacement={'start'}
                value="constant"
                control={<CustomRadio />}
                label={
                  <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                    Constant
                  </Typography>
                }
              />
              <FormControlLabel
                labelPlacement={'start'}
                value="varying"
                control={<CustomRadio />}
                label={
                  <Typography variant={'subtitle2'} color={'info.main'}>
                    Varying
                  </Typography>
                }
              />
            </RadioGroup>
          </BoxComponent>
          <BoxComponent disabled={inputState.vegetationType !== 'constant'} sx={{ ...styles.flexStartBox }}>
            <Box mr={3}>
              <LabelWrapper label={'Stems / m\u00B2'}>
                <CustomInput
                  disabled={!inputState.vegetation || inputState.vegetationType !== 'constant'}
                  min={0.00000001}
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  required
                  value={inputState.stems}
                  name={'stems'}
                  onChange={onNumberChange}
                />
              </LabelWrapper>
            </Box>
          </BoxComponent>
        </Box>
        <BoxComponent disabled={!inputState.vegetation || inputState.vegetationType !== 'varying'}>
          <LabelWrapper label={'Insert Stems / m\u00B2 file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.vegetation || inputState.vegetationType !== 'varying'}
              file={inputState.stemsFile}
              setFile={(file) => onFileChange(file, 'stemsFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      {/* <Box sx={{ ...styles.flexStartBox }}>
        <Box>
          <Typography mb={2} variant={'subtitle1'}>
            Attenuation due to vegetation
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
            <Box>
              <LabelWrapper label={'Include vegetation'}>
                <Box sx={{ ...styles.spacingCenterContainer }}>
                  <CustomSwitch checked={inputState.vegetation} name={'vegetation'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={!inputState.vegetation} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Calibration coefficient'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.coef}
                    name={'coef'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'Stem width (m)'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.width}
                    name={'width'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
              <Box mr={3}>
                <LabelWrapper label={'Stem height (m)'}>
                  <CustomInput
                    disabled={!inputState.vegetation}
                    min={0.00000001}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.height}
                    name={'height'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
        </Box>

        <BoxComponent disabled={!inputState.vegetation} mt={4}>
          <Box sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="constant" label={'Constant'} onClick={onRadioClickV}>
                  <CustomRadio checked={inputState.vegetationType === 'constant'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.vegetationType !== 'constant'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Stems / m\u00B2'}>
                  <CustomInput
                    disabled={!inputState.vegetation || inputState.vegetationType !== 'constant'}
                    min={0.00000001}
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    required
                    value={inputState.stems}
                    name={'stems'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
          <Box mt={2} sx={{ ...styles.flexEndBox, ...styles.childSpacing }}>
            <Box>
              <Box mb={1}>
                <RadioLabel id="varying" label={'Varying'} onClick={onRadioClickV}>
                  <CustomRadio checked={inputState.vegetationType === 'varying'} />
                </RadioLabel>
              </Box>
            </Box>
            <BoxComponent disabled={inputState.vegetationType !== 'varying'} sx={{ ...styles.flexStartBox }}>
              <Box mr={3}>
                <LabelWrapper label={'Insert Stems / m\u00B2 file'}>
                  <ButtonFileDialog
                    typePage={'PLOT'}
                    dataFilesOnly
                    required
                    projectId={projectId}
                    disabled={!projectId || inputState.vegetationType !== 'varying' || !inputState.vegetation}
                    file={inputState.stemsFile}
                    setFile={(file) => onFileChange(file, 'stemsFile')}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
          </Box>
        </BoxComponent>
      </Box> */}

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box>
          <Typography variant={'subtitle1'}>Sponge Layers</Typography>
          <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Include sponge layers'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.spongeLayer} name={'spongeLayer'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent width={'130px'} disabled={!inputState.spongeLayer}>
              <RadioGroup
                aria-label="spongeFriction"
                name="spongeLayerType"
                value={inputState.spongeLayerType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'start'}
                  value="perimetric"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Perimetric sponge width
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'left'} variant={'subtitle2'} color={'info.main'}>
                      Varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}>
              <LabelWrapper label={'West'}>
                <CustomInput
                  min={0}
                  step={1}
                  required
                  disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}
                  errorText={'Enter positive number'}
                  value={inputState.west}
                  name={'west'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}>
              <LabelWrapper label={'North'}>
                <CustomInput
                  min={0}
                  step={1}
                  required
                  disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}
                  errorText={'Enter positive number'}
                  value={inputState.north}
                  name={'north'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}>
              <LabelWrapper label={'East'}>
                <CustomInput
                  min={0}
                  step={1}
                  required
                  disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}
                  errorText={'Enter positive number'}
                  value={inputState.east}
                  name={'east'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}>
              <LabelWrapper label={'South'}>
                <CustomInput
                  min={0}
                  step={1}
                  required
                  disabled={!inputState.spongeLayer || inputState.spongeLayerType === 'varying'}
                  errorText={'Enter positive number'}
                  value={inputState.south}
                  name={'south'}
                  type={'number'}
                  onChange={onNumberChange}
                  maxWidth={'55px'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.spongeLayer || inputState.spongeLayerType !== 'varying'} mr={1} my={1}>
          <LabelWrapper label={'Insert sponge file'}>
            <ButtonFileDialog
              typePage={'HMS'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.spongeLayer || inputState.spongeLayerType !== 'varying'}
              file={inputState.spongeFile}
              setFile={(file) => onFileChange(file, 'spongeFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box>
          <Typography variant={'subtitle1'}>Eddy Viscosity</Typography>
          <Box sx={{ ...styles.childSpacing, ...styles.flexStartBox, maxWidth: '700px' }}>
            <Box>
              <LabelWrapper label={'Include eddy Map'}>
                <Box sx={{ ...styles.spacingJustifyContainer }}>
                  <CustomSwitch checked={inputState.eddyLayer} name={'eddyLayer'} onChange={onChangeSwitch} />
                </Box>
              </LabelWrapper>
            </Box>
            <BoxComponent width={'140px'} disabled={!inputState.eddyLayer}>
              <RadioGroup aria-label="eddyType" name="eddyType" value={inputState.eddyType} onChange={onChangeRadio}>
                <FormControlLabel
                  labelPlacement={'start'}
                  value="constant"
                  control={<CustomRadio />}
                  label={
                    <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                      Constant
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={'start'}
                  value="varying"
                  control={<CustomRadio />}
                  label={
                    <Typography variant={'subtitle2'} color={'info.main'}>
                      Varying
                    </Typography>
                  }
                />
              </RadioGroup>
            </BoxComponent>
            <BoxComponent disabled={!inputState.eddyLayer || inputState.eddyType === 'varying'}>
              <LabelWrapper label={'Coefficient'}>
                <CustomInput
                  min={0}
                  step={'any'}
                  required
                  disabled={!inputState.eddyLayer || inputState.eddyType === 'varying'}
                  errorText={'Enter positive number'}
                  value={inputState.eddyCoefficient}
                  name={'eddyCoefficient'}
                  type={'number'}
                  onChange={onNumberChange}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
        <BoxComponent disabled={!inputState.eddyLayer || inputState.eddyType !== 'varying'} mr={1} my={1}>
          <LabelWrapper label={'Insert eddy file'}>
            <ButtonFileDialog
              typePage={'HMS'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.eddyLayer || inputState.eddyType !== 'varying'}
              file={inputState.eddyFile}
              setFile={(file) => onFileChange(file, 'eddyFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
    </Box>
  );
};

export default memo(HmsEnergyDissipationInputsGroup);
