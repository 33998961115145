import React, { FC, memo, useMemo, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import { HmsBoundaryConditions } from '../../../models/inputTypes/HmsFields';
import { PmsNumericDomain } from '../../../models/inputTypes/PmsFields';
import { generatorPreparation } from '../../../utils/functions/generatorPreparation';
import BoxComponent from '../../custom/BoxComponent';
import { hmsBoundaryConditionsInitialState } from '../../../utils/initialStates/hmsInputState';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  childSpacingSmall: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 2,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type InputsProps = {
  numericalState: PmsNumericDomain;
  inputState: HmsBoundaryConditions;
  setInputState: (value: HmsBoundaryConditions) => void;
};

const HmsBoundaryConditionsInputsGroup: FC<InputsProps> = ({ numericalState, inputState, setInputState }) => {
  const [valueX, setValueX] = useState(0);
  const [valueY, setValueY] = useState(0);
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name === 'seaState' && event.target.value === '1') {
      setInputState({
        ...inputState,
        [event.target.name]: event.target.value,
        numberOfPeriods: hmsBoundaryConditionsInitialState.numberOfPeriods,
        spectrum: hmsBoundaryConditionsInitialState.spectrum,
        depthWavemaker: hmsBoundaryConditionsInitialState.depthWavemaker,
        minPeriod: hmsBoundaryConditionsInitialState.minPeriod,
        maxPeriod: hmsBoundaryConditionsInitialState.maxPeriod,
        peakFactorY: hmsBoundaryConditionsInitialState.peakFactorY,
      });
      return;
    }

    if (event.target.name === 'spectrum') {
      setInputState({
        ...inputState,
        spectrum: event.target.value as string,
        depthWavemaker: hmsBoundaryConditionsInitialState.depthWavemaker,
      });
      return;
    }

    if (event.target.name === 'numberOfDirections' && event.target.value === '1') {
      setInputState({
        ...inputState,
        numberOfDirections: +event.target.value,
        mwdDeviation: hmsBoundaryConditionsInitialState.mwdDeviation,
        directionalSpreading: hmsBoundaryConditionsInitialState.directionalSpreading,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSelectChange = (event: SelectChangeEvent<any>) => {
    const generatorLength = parseInt(event.target.value);
    const newGens = generatorPreparation(inputState.wave_generators_line_coordinates.slice(), generatorLength);
    setInputState({ ...inputState, wave_generators_line_coordinates: newGens, generatorNumber: event.target.value });
  };

  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const onDynamicInputChangeX = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+e.target.value);
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const onDynamicInputChangeY = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+e.target.value);
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const prepareSelectItems = () => {
    return [1, 2, 3, 4].map((item, i) => (
      <CustomMenuItem key={i} value={item}>
        <Typography variant={'subtitle2'}>{item}</Typography>
      </CustomMenuItem>
    ));
  };

  const onInputChangeMax = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueMax(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChangeMin = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueMin(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const landInputs = useMemo(() => {
    const inputs = [];
    for (let i = 0; i < inputState.generatorNumber; i++) {
      inputs.push(
        <Box mt={1} sx={{ ...styles.spacingContainer, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'First point'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  minWidth={'64px'}
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  type={'number'}
                  value={inputState.wave_generators_line_coordinates[i].wg_fpx}
                  onChange={(e) => onDynamicInputChange(i, e)}
                  name={'wg_fpx'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  minWidth={'64px'}
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  type={'number'}
                  value={inputState.wave_generators_line_coordinates[i].wg_fpy}
                  onChange={(e) => onDynamicInputChange(i, e)}
                  name={'wg_fpy'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Last point'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  minWidth={'64px'}
                  required
                  min={inputState.wave_generators_line_coordinates[i].wg_fpx}
                  max={numericalState.cellX}
                  step={1}
                  errorText={
                    numericalState.cellX < valueX
                      ? 'Number should be <= than total grid length'
                      : inputState.wave_generators_line_coordinates[i].wg_fpx > valueX
                      ? 'Number should be >= than first point'
                      : 'Enter positive integer'
                  }
                  type={'number'}
                  value={inputState.wave_generators_line_coordinates[i].wg_lpx}
                  onChange={(e) => onDynamicInputChangeX(i, e)}
                  name={'wg_lpx'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  minWidth={'64px'}
                  required
                  min={inputState.wave_generators_line_coordinates[i].wg_fpy}
                  max={numericalState.cellY}
                  step={1}
                  errorText={
                    numericalState.cellY < valueY
                      ? 'Number should be <= than total grid length'
                      : inputState.wave_generators_line_coordinates[i].wg_fpy > valueY
                      ? 'Number should be >= than first point'
                      : 'Enter positive integer'
                  }
                  type={'number'}
                  value={inputState.wave_generators_line_coordinates[i].wg_lpy}
                  onChange={(e) => onDynamicInputChangeY(i, e)}
                  name={'wg_lpy'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
        </Box>,
      );
    }
    return inputs;
  }, [inputState.generatorNumber, onDynamicInputChange]);

  return (
    <Box>
      <Box sx={{ flexWrap: 'wrap', ...styles.childSpacing, ...styles.flexStartBox }}>
        <Box>
          <Typography mb={1} variant={'subtitle1'}>
            Wave Generation
          </Typography>
          <Box sx={{ ...styles.childSpacingSmall, ...styles.spacingContainer }}>
            <Box>
              <LabelWrapper label={'Sea state'}>
                <CustomSelect
                  required
                  value={inputState.seaState}
                  onChange={onInputChange}
                  name={'seaState'}
                  minWidth={'140px'}
                  defaultValue={''}
                  displayEmpty
                >
                  <CustomMenuItem value={'1'}>
                    <Typography variant={'subtitle2'}>Regular</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={'2'}>
                    <Typography variant={'subtitle2'}>Irregular</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Hs (m)'}>
                <CustomInput
                  min={0.0000000001}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.hm}
                  onChange={onInputChange}
                  name={'hm'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Tp (s)'}>
                <CustomInput
                  min={0.0000000001}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.ts}
                  onChange={onInputChange}
                  name={'ts'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Direction (deg)'}>
                <CustomInput
                  min={0}
                  max={359.99}
                  required
                  step={'any'}
                  errorText={'Enter number 0-359.99'}
                  type={'number'}
                  value={inputState.direction}
                  onChange={onInputChange}
                  name={'direction'}
                />
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
        <BoxComponent disabled={inputState.seaState !== '2'}>
          <Box sx={styles.spacingContainer}>
            <Box mr={2}>
              <LabelWrapper label={'Number of Periods'}>
                <CustomInput
                  disabled={inputState.seaState !== '2'}
                  min={1}
                  step={1}
                  required
                  errorText={'Enter positive integer'}
                  type={'number'}
                  value={inputState.numberOfPeriods}
                  onChange={onInputChange}
                  name={'numberOfPeriods'}
                />
              </LabelWrapper>
            </Box>
            <Box mr={3}>
              <LabelWrapper label={'Spectrum'}>
                <CustomSelect
                  required
                  value={inputState.spectrum}
                  onChange={onInputChange}
                  name={'spectrum'}
                  minWidth={'140px'}
                  defaultValue={''}
                  displayEmpty
                >
                  <CustomMenuItem value={'1'}>
                    <Typography variant={'subtitle2'}>JONSWAP</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={'2'}>
                    <Typography variant={'subtitle2'}>TMA</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </Box>
            <Box mr={5}>
              <LabelWrapper color={inputState.spectrum === '1' ? 'grey' : undefined} label={'Depth at Wavemaker (m)'}>
                <CustomInput
                  disabled={inputState.spectrum === '1'}
                  min={0.01}
                  step={'any'}
                  required
                  errorText={'Enter number > 0.01'}
                  type={'number'}
                  value={inputState.depthWavemaker}
                  onChange={onInputChange}
                  name={'depthWavemaker'}
                />
              </LabelWrapper>
            </Box>
            <Box mr={3}>
              <LabelWrapper label={'Min Period (s)'}>
                <CustomInput
                  disabled={inputState.seaState !== '2'}
                  min={0.00000000000000001}
                  max={inputState.ts}
                  step={'any'}
                  required
                  errorText={valueMin > inputState.ts ? 'Number must be < than Tp' : 'Enter positive integer'}
                  type={'number'}
                  value={inputState.minPeriod}
                  onChange={onInputChangeMin}
                  name={'minPeriod'}
                />
              </LabelWrapper>
            </Box>
            <Box mr={4}>
              <LabelWrapper label={'Max Period (s)'}>
                <CustomInput
                  disabled={inputState.seaState !== '2'}
                  min={inputState.ts}
                  step={'any'}
                  required
                  errorText={
                    valueMax < inputState.ts && valueMax > 0 ? 'Number must be > than Tp' : 'Enter positive integer'
                  }
                  type={'number'}
                  value={inputState.maxPeriod}
                  onChange={onInputChangeMax}
                  name={'maxPeriod'}
                />
              </LabelWrapper>
            </Box>
            <Box mr={4}>
              <LabelWrapper label={'Peak Factor y'}>
                <CustomInput
                  disabled={inputState.seaState !== '2'}
                  min={0.01}
                  errorText={'Enter number > 0.01'}
                  step={'any'}
                  required
                  type={'number'}
                  value={inputState.peakFactorY}
                  onChange={onInputChange}
                  name={'peakFactorY'}
                />
              </LabelWrapper>
            </Box>
          </Box>
          <Box mt={2} sx={styles.spacingContainer}>
            <Box mr={3}>
              <LabelWrapper label={'Number of Directions'}>
                <CustomInput
                  disabled={inputState.seaState !== '2'}
                  min={1}
                  step={1}
                  required
                  errorText={'Enter positive integer'}
                  type={'number'}
                  value={inputState.numberOfDirections}
                  onChange={onInputChange}
                  name={'numberOfDirections'}
                />
              </LabelWrapper>
            </Box>
            <BoxComponent disabled={inputState.seaState !== '2' || inputState.numberOfDirections == 1} mr={2}>
              <LabelWrapper label={'Deviation From MWD (Deg)'}>
                <CustomInput
                  disabled={inputState.seaState !== '2' || inputState.numberOfDirections == 1}
                  min={0.01}
                  errorText={'Enter number > 0.01'}
                  step={'any'}
                  required
                  type={'number'}
                  value={inputState.mwdDeviation}
                  onChange={onInputChange}
                  name={'mwdDeviation'}
                />
              </LabelWrapper>
            </BoxComponent>

            <BoxComponent disabled={inputState.seaState !== '2' || inputState.numberOfDirections == 1} mr={4}>
              <LabelWrapper label={'Directional Spreading S'}>
                <CustomInput
                  disabled={inputState.seaState !== '2' || inputState.numberOfDirections == 1}
                  min={0.01}
                  errorText={'Enter number > 0.01'}
                  step={'any'}
                  required
                  type={'number'}
                  value={inputState.directionalSpreading}
                  onChange={onInputChange}
                  name={'directionalSpreading'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </BoxComponent>
      </Box>
      <Grid mt={2} container>
        <Box mr={2}>
          <Typography mb={2} variant={'subtitle1'}>
            Wave Generation Lines
          </Typography>
          <Box sx={{ ...styles.spacingContainer }}>
            <Box mr={3}>
              <LabelWrapper label={'Number of wave generators'}>
                <CustomSelect
                  value={inputState.generatorNumber}
                  onChange={onSelectChange}
                  name={'generatorNumber'}
                  minWidth={'140px'}
                  defaultValue={''}
                  displayEmpty
                >
                  {prepareSelectItems()}
                </CustomSelect>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant={'subtitle1'}>Wave Generation Coordinates</Typography>
          {landInputs}
        </Box>
      </Grid>
    </Box>
  );
};

export default memo(HmsBoundaryConditionsInputsGroup);
