import React from 'react';
import ReactDataSheet from 'react-datasheet';
import './file-data-sheet.css';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | null;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

interface DataSheetProps {
  grid: any[][] | undefined;
  setGrid: (grid: any[][]) => void;
  coordinates: { x: number; y: number };
  setCoordinates: (coordinates: { x: number; y: number }) => void;
  dimensions: [number, number] | undefined;
  disabled: boolean | undefined;
}

interface DataSheetState {
  selection: ReactDataSheet.Selection | undefined;
  selectionFlag: ReactDataSheet.Selection | undefined;
}

export default class FileDataSheet extends React.Component<DataSheetProps, DataSheetState> {
  state: DataSheetState = { selection: undefined, selectionFlag: undefined };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydown.bind(this));
  }

  onKeydown(event: KeyboardEvent) {
    if (this.props.disabled) return;
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (
          this.areSelectionsEqual(this.state.selection, this.state.selectionFlag) &&
          this.state.selection &&
          (this.state.selection.start.i === 1 || this.state.selection.end.i === 1)
        ) {
          if (this.props.coordinates.y - 20 > 0) {
            this.props.setCoordinates({ x: this.props.coordinates.x, y: this.props.coordinates.y - 1 });
          }
        } else if (
          this.props.coordinates.y - 20 > 0 &&
          this.state.selection &&
          (this.state.selection.start.i === 1 || this.state.selection.end.i === 1)
        ) {
          this.setState({
            selectionFlag: {
              start: {
                i: this.state.selection.start.i < 1 ? 1 : this.state.selection.start.i,
                j: this.state.selection.start.j,
              },
              end: {
                i: this.state.selection.end.i < 1 ? 1 : this.state.selection.end.i,
                j: this.state.selection.end.j,
              },
            },
          });
        } else {
          this.setState({ selectionFlag: undefined });
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (
          this.areSelectionsEqual(this.state.selection, this.state.selectionFlag) &&
          this.state.selection &&
          (this.state.selection.start.i === 20 || this.state.selection.end.i === 20)
        ) {
          if (this.props.coordinates.y < (this.props.dimensions?.[0] ?? 0)) {
            this.props.setCoordinates({ x: this.props.coordinates.x, y: this.props.coordinates.y + 1 });
          }
        } else if (
          this.props.coordinates.y < (this.props.dimensions?.[0] ?? 0) &&
          this.state.selection &&
          (this.state.selection.start.i === 20 || this.state.selection.end.i === 20)
        ) {
          this.setState({
            selectionFlag: this.state.selection,
          });
        } else {
          this.setState({ selectionFlag: undefined });
        }
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (
          this.areSelectionsEqual(this.state.selection, this.state.selectionFlag) &&
          this.state.selection &&
          (this.state.selection.start.j === 1 || this.state.selection.end.j === 1)
        ) {
          if (this.props.coordinates.x > 0) {
            this.props.setCoordinates({ x: this.props.coordinates.x - 1, y: this.props.coordinates.y });
          }
        } else if (
          this.props.coordinates.x > 0 &&
          this.state.selection &&
          (this.state.selection.start.j === 1 || this.state.selection.end.j === 1)
        ) {
          this.setState({
            selectionFlag: {
              start: {
                i: this.state.selection.start.i,
                j: this.state.selection.start.j < 1 ? 1 : this.state.selection.start.j,
              },
              end: {
                i: this.state.selection.end.i,
                j: this.state.selection.end.j < 1 ? 1 : this.state.selection.end.j,
              },
            },
          });
        } else {
          this.setState({ selectionFlag: undefined });
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (
          this.areSelectionsEqual(this.state.selection, this.state.selectionFlag) &&
          this.state.selection &&
          (this.state.selection.start.j === 20 || this.state.selection.end.j === 20)
        ) {
          if (this.props.coordinates.x + 20 < (this.props.dimensions?.[1] ?? 0)) {
            this.props.setCoordinates({ x: this.props.coordinates.x + 1, y: this.props.coordinates.y });
          }
        } else if (
          this.props.coordinates.x + 20 < (this.props.dimensions?.[1] ?? 0) &&
          this.state.selection &&
          (this.state.selection.start.j === 20 || this.state.selection.end.j === 20)
        ) {
          this.setState({
            selectionFlag: this.state.selection,
          });
        } else {
          this.setState({ selectionFlag: undefined });
        }
        break;
    }
  }

  areSelectionsEqual(
    selection: ReactDataSheet.Selection | undefined,
    selectionFlag: ReactDataSheet.Selection | undefined,
  ) {
    return (
      selection !== undefined &&
      selectionFlag !== undefined &&
      selection.start.i === selectionFlag.start.i &&
      selection.start.j === selectionFlag.start.j &&
      selection.end.i === selectionFlag.end.i &&
      selection.end.j === selectionFlag.end.j
    );
  }

  gridToRender(grid: any[][]) {
    const newGrid: GridElement[][] = [
      [
        { readOnly: true, value: null },
        { value: this.props.dimensions ? this.props.coordinates.x + 1 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 2 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 3 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 4 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 5 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 6 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 7 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 8 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 9 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 10 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 11 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 12 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 13 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 14 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 15 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 16 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 17 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 18 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 19 : null, readOnly: true },
        { value: this.props.dimensions ? this.props.coordinates.x + 20 : null, readOnly: true },
      ],
    ];

    grid?.forEach((row, index) => {
      const newRow: GridElement[] = [
        {
          value: this.props.dimensions
            ? (this.props.dimensions?.[0] ?? 0) - this.props.coordinates.y + (20 - index)
            : null,
          readOnly: true,
        },
      ];
      row.forEach((cell) => newRow.push({ value: cell ?? null, readOnly: false }));
      newGrid.push(newRow);
    });

    return newGrid;
  }

  handleSetGrid(grid: GridElement[][]) {
    const newGrid: any[][] = [];

    grid?.forEach((row, index) => {
      const newRow: any[] = [];
      if (index > 0) {
        row.forEach((cell, index) => {
          if (index > 0) {
            newRow.push(cell.value);
          }
        });
        newGrid.push(newRow);
      }
    });

    this.props.setGrid(newGrid);
  }

  render() {
    return (
      <MyReactDataSheet
        onSelect={(selection) => {
          if (selection.start.i > 0 && selection.start.j > 0 && selection.end.i > 0 && selection.end.j > 0) {
            this.setState({ selection: selection });
          }
        }}
        selected={this.state.selection}
        data={this.props.grid ? this.gridToRender(this.props.grid) : EMPTY_GRID}
        valueRenderer={(cell) => cell.value}
        onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
        onCellsChanged={(changes) => {
          const grid = this.props.grid ? this.gridToRender(this.props.grid) : EMPTY_GRID;
          changes.forEach(({ cell, row, col, value }) => {
            grid[row][col] = { ...grid[row][col], value: Number.parseFloat(value as any) };
          });
          this.handleSetGrid(grid);
        }}
      />
    );
  }
}

export const EMPTY_GRID = [
  [
    { readOnly: true, value: null },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
    { value: null, readOnly: true },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
  [
    { value: null, readOnly: true },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ],
];
