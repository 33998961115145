import {
  MarisPlotDataSelection,
  MarisPlotFigureProperties,
  MarisPlotLabelPdf,
  MarisPlotPrintSetup,
} from '../../models/inputTypes/MarisPlotFields';

export const marisPlotDataSelectionInitialState: MarisPlotDataSelection = {
  project: '',
  plotSetupFile: undefined,
  dataFile: undefined,
  plotVector: false,
  directionFile: undefined,
  numericalX: 0,
  numericalY: 0,
  numericalDX: 2.5,
  numericalDY: 2.5,
  nePointX: 1,
  nePointY: 1,
  swPointX: 1,
  swPointY: 1,
  subSeriesType: 'stationary',
  frame: 1,
};

export const marisPlotFigurePropertiesInitialState: MarisPlotFigureProperties = {
  title: '',
  paletteColors: '1',
  minMaxValues: false,
  minValue: 0,
  maxValue: 0,
  paletteNumberOfLevels: 11,
  paletteFontSize: 5,
  isoLines: false,
  transparency: 0.5,
  contoursNumberOfLevels: 11,
  contoursFontSize: 5,
  vectorColors: '1',
  plotVector: false,
  vectorIntervalX: 10,
  vectorIntervalY: 10,
  vectorScaling: 0.05,
  plotLand: false,
  plotLandFile: undefined,
  landColor: '1',
  plotBasemap: false,
  basemapCodeValue: 10,
  inpEPSG: parseInt(''),
  originX: parseFloat(''),
  originY: parseFloat(''),
  mapProvider: 1,
  axesType: 'meter',
  majorGridLines: false,
  yAxisTitle: '',
  xAxisTitle: '',
  axeFontSize: 5,
};

export const marisPlotPrintSetupInitialState: MarisPlotPrintSetup = {
  label: false,
  subSeriesType: 'pdf',
  pageOrientation: '2',
  imageDPI: '4',

  north_arrow: false,
  north_arrow_pos: 1,
  north_arrow_colour: 1,
  north_arrow_rot: 0,
};
export const marisPlotLabelPdfInitialState: MarisPlotLabelPdf = {
  userIdType: 'username',
  username: '',
  logoFile: undefined,
  modelApplied: '1',
  client: '',
  projectTitle: '',
  figureDescription: '',
  figureNumber: '',
  figureTitle: '',
  date: '',
};
