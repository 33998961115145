import React, { FC, useMemo, useState } from 'react';
import PageContainer from '../components/structural/PageContainer';
import { Grid, Typography, TableCell, TableRow, CircularProgress, IconButton } from '@mui/material';
import CustomTable from '../components/custom/CustomTable';
import { Job } from '../models/types/Job';
import ConfirmationModal from '../components/dialogs/ConfirmationModal';
import { useGetJobsQuery } from '../redux/RTK/queries/jobQuery';
import { useDeleteAllJobsMutation, useDeleteJobsMutation } from '../redux/RTK/mutations/jobsMutations';
import moment from 'moment-timezone';
import CustomProgress from '../components/custom/CustomProgress';
import { ReactComponent as DeleteIcon } from '../assets/images/delete.svg';
import CustomButton from '../components/custom/CustomButton';
import { sortProjectFiles } from '../utils/functions/sortProjectFiles';
import { useStorageQuery } from '../redux/RTK/queries/storageQuery';

const TableHead = [
  { title: 'NAME', align: 'flex-start' },
  { title: 'DATE SUBMITED ', align: 'center' },
  { title: 'STATUS', align: 'center' },
  { title: 'PROGRESS', align: 'center' },
  { title: '', align: 'center' },
];

const Jobs: FC = () => {
  const [deleteAllJobs] = useDeleteAllJobsMutation();
  const { data, isLoading } = useGetJobsQuery({}, { pollingInterval: 10000 });
  const { data: storage } = useStorageQuery({});
  const [deleteJobs] = useDeleteJobsMutation();
  const [deleteJob, setDeleteJob] = useState<Job | undefined>();
  const [deleteAll, setDeleteAll] = useState(false);
  const closeDeleteModal = () => {
    setDeleteJob(undefined);
  };

  const onDeleteAll = () => {
    setDeleteAll(true);
  };

  const onDeleteAllFiles = () => {
    deleteAllJobs({});
    setDeleteAll(false);
  };

  const onDeleteAccept = () => {
    if (deleteJob?.uuid) deleteJobs(deleteJob.uuid);
    setDeleteJob(undefined);
  };

  const dataUtc = useMemo(() => {
    if (storage && storage.profile) {
      const utc = storage.profile.timezone;
      if (utc) {
        return utc.slice(utc.indexOf('C') + 1, utc.lastIndexOf(')'));
      } else return '';
    } else return '';
  }, [storage]);

  const tableRows = useMemo(() => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <CircularProgress color={'info'} />
          </TableCell>
        </TableRow>
      );
    }

    if (!data?.length) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <Typography my={1} variant={'subtitle1'}>
              No available Jobs
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (data?.length) {
      const sortedData = sortProjectFiles(data.slice(), 'DATE', true);
      return sortedData
        .slice()
        .reverse()
        .map((item, i) => (
          <>
            <TableRow key={item.uuid + i}>
              <TableCell>
                <Typography variant={'subtitle2'}>
                  <strong>{item.simulation.toUpperCase()}: </strong>
                  {item.name}
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'subtitle2'}>
                  {moment(item.created_at).zone(dataUtc).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography
                  color={item.status === 'finished' ? 'primary' : 'info.main'}
                  variant={'button'}
                  sx={{ fontWeight: '600' }}
                >
                  {item.status}
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <CustomProgress
                  progresValue={item.progress}
                  progresStatus={item.status}
                  title={item.progress.toString()}
                  value={item.progress}
                />
              </TableCell>
              <TableCell sx={{ width: 75 }} align={'center'}>
                <IconButton onClick={() => setDeleteJob(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow key={item.uuid + 'a'}>
              <TableCell colSpan={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'} color={'text.disabled'}>
                      {'Project: ' + item.project_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography padding={'8px 0px'} variant={'subtitle2'} color={'text.disabled'}>
                      {'ID: ' + item.uuid}
                    </Typography>
                  </Grid>
                  <Grid container spacing={0.5}>
                    <Grid item xs={0}>
                      <Typography variant={'subtitle2'} color={'text.disabled'}>
                        Notes:
                      </Typography>
                    </Grid>
                    <Grid item xs={0}>
                      <Typography
                        variant={'subtitle2'}
                        color={
                          item.fail_color === 'red'
                            ? 'error'
                            : item.fail_color === 'green'
                            ? 'primary'
                            : 'text.disabled'
                        }
                      >
                        {item.fail_comment || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </>
        ));
    }
  }, [data, isLoading]);

  return (
    <PageContainer subTitle={'Jobs'}>
      <Grid container>
        <Grid item container alignItems={'flex-end'} justifyContent={'space-between'}>
          <Grid item>
            <Typography my={3} variant="h1">
              Jobs
            </Typography>
          </Grid>
          {data?.length ? (
            <Grid my={3} mr={1} item>
              <CustomButton onClick={onDeleteAll} variant={'contained'} color={'error'}>
                DELETE ALL
              </CustomButton>
            </Grid>
          ) : undefined}
        </Grid>
        <Grid item xs={12}>
          <CustomTable header={TableHead}>{tableRows}</CustomTable>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={!!deleteJob}
        handleClose={closeDeleteModal}
        message={'Are you sure you want to delete job: ' + deleteJob?.name + '?'}
        handleAccept={onDeleteAccept}
      />
      <ConfirmationModal
        open={deleteAll}
        closeLabel={'Cancel'}
        acceptLabel={'Confirm'}
        handleClose={() => setDeleteAll(false)}
        message={'You are about to delete all jobs. This process cannot be undone. Are you sure you want to continue?'}
        handleAccept={onDeleteAllFiles}
      />
    </PageContainer>
  );
};

export default Jobs;
